import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import { withUserContext } from "../../contexts/UserContext";
import BasicTabs from "../SharedComponents/BasicTabs/BasicTabs";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicTextArea from "../SharedComponents/BasicTextArea/BasicTextArea";
import "./ShowsModal.scss";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MessageModal from "../SharedComponents/MessageModal";
import {
  criteriaList,
  OperatorList,
  GroupByOption,
  initialUserDetails,
} from "./Config";
import { validateNumbersOnly } from "../../Common/Helper";
import { CircularProgress } from "@material-ui/core";
import * as Constants from "../../constants/constants";
import PostWatchService from "../../services/service";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Message from "../../Common/Messages.json";
import { MDBIcon } from "mdbreact";

class NewTenantShowEpisodes extends React.Component {
  constructor(props) {
    super(props);
    this.deleteItem = null;
    this.placeHolderObj_ProdCompany = {
      prod_company_id: null,
      currency_id: null,
      exchange_rate: null,
      is_deleted: 0,
    };
    this.placeHolderObj_Episode = {
      episode_id: null,
      episode_name: null,
      gl_number: null,
      is_deleted: 0,
    };
    this.seasonSelectedIndexCopy = null;
    this.state = {
      userPostJson: null,
      isFetchingEpisodes: false,
      postInitiated: false,
      reportsTabs: [
        { tab_id: 1, tab_name: "Details" },
        { tab_id: 2, tab_name: "Episodes" },
      ],
      selectedTabValue: 1,
      criteriaDetails: null,
      operator: null,
      filters: { comp_name: null, page: null, rowsPerPage: null },
      productionCompanies: null,
      isFetchingCurrencies: false,
      currencies: null,
      haveUnsavedChanges: false,
      isMessageModalOpen: false,
      seasonsList: null,
      seasonDropDownValue: null,
      episodeSelected: null,
      seasonSelected: null,
      seasonSelectedIndex: null,
      duplicateSeasonEror: false,
      hasError: false,
      isConfirmMessageModalOpen: false,
      isErrorMessageModalOpen: false,
      currentPoSTartNo:null,
      currents4_code:null,
      validateS4code:false,
      current_task:null,
      current_task_description:null,
      current_show_season_id:null,
      editShowSeasonId:null
    };
  }
  componentDidMount = () => {
    // For both New and Existing show
    this.getEpisodes(this.props.show_id);
    this.getSeasons();
  };

  getEpisodes = (showId) => {
    this.setState({ isFetchingEpisodes: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getshowepisodes?showId=" + showId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            userPostJson: response.data[0],
            isFetchingEpisodes: false,
          },
          () => {
            if (this.state.userPostJson?.show_season?.length > 0) {
              this.handleOnSeasonSelect(null, 0);
            }
          }
        );
      },
      (err) => {
        this.setState({ isFetchingEpisodes: false });
      }
    );
  };

  getSeasons = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getseasons",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          seasonsList: response.data,
        });
      },
      (err) => {
        console.log("error in get seasons");
      }
    );
  };

  handleOnChange = (field, value) => {
  this.setState({[`current_${field}`] : value})
  };

  handlePOStartNoChange = (value) => {    
     this.setState({ currentPoSTartNo:value});
  };

  handleS4CodeChange = (value) => {
    if(value.length === 0){
      this.setState({validateS4code:false});
    }else if(value.length > 0 && value.length <=10){
      const regex = /^[A-Za-z]\d{2}\.\d{6}$/;
      const validStatus = !regex.test(value);
      this.setState({validateS4code:validStatus});
    }else if(value.length > 10 && value.length <=13){
      const regex = /^[A-Za-z]\d+\.\d{6,}\.\d{2}$/;
      const validStatus = !regex.test(value);
      this.setState({validateS4code:validStatus});
    }
    else if(value.length > 13 && value.length <=16){
      const regex = /^[A-Za-z]\d{2}\.\d{6}\.\d{2}\.\d{2}$/;
      const validStatus = !regex.test(value);
      this.setState({validateS4code:validStatus});
    }
    else if(value.length > 16 && value.length <=19){
      const regex = /^[A-Za-z]\d{2}\.\d{6}\.\d{2}\.\d{2}\.\d{2}$/;
      const validStatus = !regex.test(value);
      this.setState({validateS4code:validStatus});
    }else{
      this.setState({validateS4code:true});
    }

    this.setState({ currents4_code:value });
  };

  handleEpisodeChange = (field, index, value) => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    userPostJson.show_season[this.state.seasonSelectedIndex].episodes[index][
      field
    ] = value;
    this.setState({ userPostJson, haveUnsavedChanges: true });
    this.props.updatehaveUnsavedChanges(true);
  };

  addEpisode = () => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    if (userPostJson.show_season[this.state.seasonSelectedIndex]?.episodes) {
      if (
        !_.find(
          userPostJson.show_season[this.state.seasonSelectedIndex].episodes,
          this.placeHolderObj_Episode
        )
      ) {
        userPostJson.show_season[this.state.seasonSelectedIndex].episodes.push({
          ...this.placeHolderObj_Episode,
        });
      }
    }
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };
  removeEpisode = (index) => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    // userPostJson.prod_companies.splice(index, 1);
    userPostJson.show_season[this.state.seasonSelectedIndex].episodes[
      index
    ].is_deleted = 1;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };

  removeProdCompany = (index) => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    // userPostJson.prod_companies.splice(index, 1);
    userPostJson.prod_companies[index].is_deleted = 1;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };

  addOtherCurrency = () => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    if (userPostJson?.other_show_currencies) {
      userPostJson.other_show_currencies.push({
        ...this.placeHolderObj_OtherCurrency,
      });
    }
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };
  removeOtherCurrency = (index) => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    // userPostJson.other_show_currencies.splice(index, 1);
    userPostJson.other_show_currencies[index].is_deleted = 1;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };

  validateBeforeSave = (userPostJson) => {
    let canSubmit =
      userPostJson?.show_name?.length > 0 &&
      userPostJson?.po_prefix?.length > 0;
    return canSubmit;
  };

  convertToNumbers = (userPostJson) => {
    if (userPostJson?.prod_companies?.length > 0) {
      _.forEach(userPostJson.prod_companies, (obj) => {
        obj.exchange_rate = parseFloat(obj.exchange_rate);
      });
    }
    if (userPostJson?.other_show_currencies?.length > 0) {
      _.forEach(userPostJson.other_show_currencies, (obj) => {
        obj.other_curr_exch_rate = parseFloat(obj.other_curr_exch_rate);
      });
    }
    return userPostJson;
  };
  clearEmptyEpisodes = (show_season) => {
    _.forEach(show_season, (item) => {
      item.episodes = item.episodes.filter(
        (obj) => obj.episode_name && obj.gl_number
      );
    });
    return show_season;
  };
  onSave = () => {
    this.setState({ postInitiated: true });
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    if ((this.props?.userContext?.active_tenant?.tenant_id || this.props?.userContext?.user_profile?.tenant_id) != 2) {
      let show_season = this.clearEmptyEpisodes(userPostJson.show_season);
      userPostJson.show_season = show_season;
    }   
    if (this.validateBeforeSave(userPostJson)) {
      if ((this.props?.userContext?.active_tenant?.tenant_id || this.props?.userContext?.user_profile?.tenant_id) != 2) {
      userPostJson.show_season =
        userPostJson.show_season[this.state.seasonSelectedIndex];
      }
      this.saveShowEpisodes(userPostJson);
    }
  };

  saveShowEpisodes = (userPostJson) => {
    this.setState({ isFetchingEpisodes: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
        `/postshowepisodes?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      userPostJson,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          this.setState({
            isFetchingEpisodes: false,
            hasError: response.data[0]?.Message,
          });
        } else {
          this.setState({
            haveUnsavedChanges: false,
            isFetchingEpisodes: false,
            postInitiated: false,
            hasError: false,
          });
          this.getEpisodes(this.props.show_id);
          this.props.updatehaveUnsavedChanges(false);
          this.props.onSave();
        }
      },
      (err) => {
        this.setState({ isFetchingEpisodes: false, hasError: false });
        console.log("Post polineitem error:" + err);
      }
    );
  };
  onSeasonSelection = (value) => {    
    this.setState({ seasonDropDownValue: value, hasError: false },()=>{
      this.checkDuplicateSeason();
    });
  };

  checkDuplicateSeason = () => {
    let season = _.find(this.state.userPostJson?.show_season, {
      season_id: this.state.seasonDropDownValue,
    });
    if (season) {
      this.setState({ duplicateSeasonEror: true });
      return true;
    } else {
      this.setState({ duplicateSeasonEror: false });
      return false;
    }
  };

  editShowSeason(showDetails){
    this.setState({seasonDropDownValue:showDetails?.season_id,currentPoSTartNo:showDetails?.po_start_no,
          currents4_code:showDetails?.s4_code,
          current_task:showDetails?.task,
          current_task_description:showDetails?.task_description,
          current_show_season_id: showDetails?.show_season_id,
          editShowSeasonId:showDetails?.season_id,
          validateS4code:false
    })
    //this.removeShowSeason(showDetails);
    
  }

  removeShowSeason(showDetails){
    let show_seasonsList = this.state.userPostJson;
    console.log(this.state.userPostJson);
    const filteredSeasons = show_seasonsList?.show_season?.filter(season => season.season_id !== showDetails?.season_id);
    show_seasonsList.show_season = filteredSeasons;
    this.setState({ userPostJson: show_seasonsList})
  }

  addSeason = () => {
    if (!this.state.editShowSeasonId && this.state.seasonDropDownValue && !this.checkDuplicateSeason()) {
      let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
      let item = _.find(this.state.seasonsList, {
        season_id: this.state.seasonDropDownValue,
      });
      if (item) {
        userPostJson.show_season.push({
          season_id: item.season_id,
          season_name: item.season_name,
          show_season_id: this.state.current_show_season_id ?? null,
          po_start_no: this.state.currentPoSTartNo,
          s4_code:this.state.currents4_code,
          task:this.state.current_task,
          task_description:this.state.current_task_description,
          episodes: [],
        });
        this.setState({ userPostJson,currentPoSTartNo:null,seasonDropDownValue:null ,current_task:null,current_task_description:null,currents4_code:null,current_show_season_id:null, seasonSelected:null});
        this.props.updatehaveUnsavedChanges(true);
      }
    }else{
      let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
      const seasonIndex = userPostJson.show_season.findIndex(season => season.season_id === this.state.seasonDropDownValue);
  
      // If the season exists, update it
      if (seasonIndex !== -1) {
        userPostJson.show_season[seasonIndex] = { 
          season_id: userPostJson.show_season[seasonIndex].season_id,
          season_name: userPostJson.show_season[seasonIndex].season_name,
          show_season_id: this.state.current_show_season_id ?? null,
          po_start_no: this.state.currentPoSTartNo,         
          s4_code: this.state.currents4_code,
          task: this.state.current_task,
          task_description: this.state.current_task_description
        };
      }
      this.setState({ userPostJson,currentPoSTartNo:null,seasonDropDownValue:null ,current_task:null,current_task_description:null,currents4_code:null,current_show_season_id:null, seasonSelected:null,editShowSeasonId:null});
        this.props.updatehaveUnsavedChanges(true);
    }
  
  };
  handleOnSeasonSelect = (item, index) => {
    if (this.state.seasonSelectedIndex === index) {
      return false;
    }
    if (this.state.haveUnsavedChanges) {
      // this.props.handleMessageModalStatus("unsaved", true);
      this.seasonSelectedIndexCopy = index;
      this.setState({ isMessageModalOpen: true });
    } else {
      let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
      if (
        !_.find(
          userPostJson.show_season[index]?.episodes,
          this.placeHolderObj_Episode
        )
      ) {
        userPostJson.show_season[index]?.episodes.push({
          ...this.placeHolderObj_Episode,
        });
      }
      this.setState({
        seasonSelected: this.state.userPostJson?.show_season[index],
        seasonSelectedIndex: index,
        hasError: false,
        userPostJson,
      });
    }
  };

  onModalConfirm = () => {
    this.setState(
      {
        haveUnsavedChanges: false,
        isMessageModalOpen: false,
      },
      () => {
        this.handleOnSeasonSelect(null, this.seasonSelectedIndexCopy);
      }
    );

    this.props.updatehaveUnsavedChanges(false);
  };

  onDeleteSeason = () => {
    if (
      this.state.seasonSelectedIndex !== null &&
      this.state.seasonSelectedIndex >= 0
    ) {
      this.deleteItem = this.state.userPostJson;
      this.setState({ isConfirmMessageModalOpen: true });
    }
  };
  onModalDeleteConfirm = () => {
    let item = { ...this.deleteItem };
    item.is_deleted = 1;
    this.postDelete();
  };
  onErrorModalConfirm = () => {
    this.setState({
      isErrorMessageModalOpen: false,
    });
    this.deleteItem = null;
  };

  postDelete = () => {
    this.setState({ isFetchingEpisodes: true });
    PostWatchService.postDataWithoutPayload(
      Constants.postWatchServiceBaseUrl +
        `/deleteshowseason?userId=` +
        this.props?.userContext?.user_profile?.user_id +
        "&showSeasonId=" +
        this.state.userPostJson?.show_season[this.state.seasonSelectedIndex]
          ?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          this.setState({
            isErrorMessageModalOpen: true,
            isConfirmMessageModalOpen: false,
            isFetchingEpisodes: false,
          });
        } else {
          this.setState({
            isConfirmMessageModalOpen: false,
            isFetchingEpisodes: false,
            seasonSelected: null,
            seasonSelectedIndex: null,
          });
          this.getEpisodes(this.props.show_id);
          this.props.onSave();
          this.deleteItem = null;
        }
      },
      (err) => {
        this.setState({
          isConfirmMessageModalOpen: false,
          isFetchingEpisodes: false,
        });
        console.log("Post polineitem error:" + err);
      }
    );
  };

  render() {
    let isLoading = this.state.isFetchingEpisodes;
    return (
      <div>
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <MDBContainer className="Reports p-0">
              <>
                <MDBRow>
                  <MDBCol md={12}>
                    <MDBRow>
                      <MDBCol md={12} >
                      <MDBRow>
                      <MDBCol md={2}>  <BasicLabel text="Seasons" type={"text"} /> </MDBCol>
                      <MDBCol md={2}> <BasicLabel text="PO Start No" type={"text"} /> </MDBCol>
                      <MDBCol md={2}> <BasicLabel text="S4 Code" type={"text"} /> </MDBCol>
                      <MDBCol md={2}> <BasicLabel text="Task" type={"text"} /> </MDBCol>
                      <MDBCol md={4}> <BasicLabel text="Task Description" type={"text"} /> </MDBCol>
                      </MDBRow>
                        <div className="newtenant-season-button-container">
                          {this.state.userPostJson?.show_season?.map(
                            (item, index) => {
                              return (
                                <>
                                  <MDBRow>
                                  <MDBCol md={2}> 
                                  <span >{item?.season_name} </span>
                                  </MDBCol>
                                  <MDBCol md={2} > 
                                  <span >{item?.po_start_no} </span>
                                  </MDBCol>
                                  <MDBCol md={2} > 
                                  <span >{item?.s4_code} </span>
                                  </MDBCol>
                                  <MDBCol md={2} > 
                                  <span >{item?.task} </span>
                                  </MDBCol>
                                  <MDBCol md={4} className="task_desc_cont"> 
                                  <span >{item?.task_description} </span>
                                  <div className="actionEditEpisode">
                                      <MDBIcon
                                        icon={"pen"}
                                        size='sm'
                                        className="action-icons ms-1"
                                        onClick={() => { this.editShowSeason(item)}}
                                      />
                                      <MDBIcon
                                        icon={"times"}
                                        size='sm'
                                        className="action-icons ms-1"
                                        onClick={() => { this.removeShowSeason(item)}}
                                      />
                                      </div>
                                  </MDBCol>
                                    {/* <MDBCol md={3} className="actionEditEpisode">
                                      {
                                      !item?.show_season_id  &&
                                      <>
                                      <MDBIcon
                                        icon={"pen"}
                                        size='sm'
                                        className="action-icons ms-1"
                                        onClick={() => { this.editShowSeason(item)}}
                                      />
                                      <MDBIcon
                                        icon={"times"}
                                        size='sm'
                                        className="action-icons ms-1"
                                        onClick={() => { this.removeShowSeason(item)}}
                                      />
                                      </>
                                      }
                                    </MDBCol> */}
                                  </MDBRow>
                                </>
                              );
                            }
                          )}
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                     
                     
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={2} className="p-0">
                        <SelectField
                          size="small"
                          options={
                            this.state.seasonsList?.map((item) => ({
                              value: item.season_id,
                              label: item.season_name,
                              is_active: item.is_active,
                            })) || []
                          }
                          disabled={this.state.current_show_season_id}
                          value={this.state.seasonDropDownValue || null}
                          onChange={(e) => {
                            this.onSeasonSelection(e.target.value);
                          }}
                          placeholder={
                            this.props?.phoneTypePlaceholder || "-Select-"
                          }
                        />
                        </MDBCol>
                        <MDBCol md={2} className="p-0">
                         <BasicTextField
                          //label={"PO Start Nov"}
                          placeholder={"PO Start No"}
                          value={
                            this.state.currentPoSTartNo || ""
                          }
                          disabled={this.state.current_show_season_id}
                          onChange={(e) =>
                            e.target.value.length > 0
                              ? validateNumbersOnly(e.target.value) &&
                                this.handlePOStartNoChange(e.target.value)
                              : this.handlePOStartNoChange(null)
                          }
                          
                        />
                        </MDBCol>
                        <MDBCol md={2} className="p-0">
                         <BasicTextField
                          isMandatory={false}
                          limit={"50"}
                          inValidInput={"Invalid S4 code"}
                          fieldValid={this.state.validateS4code}
                          placeholder={"S4 Code"}
                          value={
                            this.state.currents4_code || ""
                          }
                          onChange={(e) =>
                            this.handleS4CodeChange(e.target.value)
                          }
                          
                        />
                        </MDBCol>
                        <MDBCol md={2} className="p-0">
                         <BasicTextField
                          isMandatory={false}
                          limit={"50"}
                          placeholder={"Task"}
                          value={
                            this.state.current_task || ""
                          }
                          onChange={(e) =>
                            this.handleOnChange('task',e.target.value)
                          }
                          
                        />
                        </MDBCol>
                        <MDBCol md={4} className="p-0 task_desc_cont">
                         <BasicTextField
                          isMandatory={false}
                          placeholder={"Task Description"}
                          value={
                            this.state.current_task_description || ""
                          }
                          onChange={(e) =>
                            this.handleOnChange('task_description',e.target.value)
                          }
                          
                        />
                        <AddCircleIcon
                          className="m-1"
                          onClick={!this.state.validateS4code && !this.state.duplicateSeasonEror && this.addSeason}
                        />
                        </MDBCol>
                        </MDBRow>
                    <MDBRow>
                      <MDBCol md={12} className="p-0 redStar f-65rem mt-1">
                        {this.state?.duplicateSeasonEror && (
                          <span className="redStar">
                            This season already exists
                          </span>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={9}>
                    <MDBRow>
                      <MDBCol md={4} className="p-0">                       
                      </MDBCol>                     
                    </MDBRow>                   
                  </MDBCol>
                </MDBRow>

                <MDBRow className="btn-row">
                  <MDBCol md={8}>
                    {this.state?.hasError && (
                      <span className="redStar f-65rem">
                        {this.state?.hasError}
                      </span>
                    )}
                  </MDBCol>
                  <MDBCol md={2}>
                    <BasicButton
                      variant="contained"
                      type="inline"
                      text={"Save"}
                      onClick={this.onSave}
                    />
                  </MDBCol>
                  <MDBCol md={2}>
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Cancel"}
                      onClick={this.props.onClose}
                      disabled={false}
                    />
                  </MDBCol>
                </MDBRow>
                <MessageModal
                  open={this.state.isMessageModalOpen}
                  showError={this.state.haveUnsavedChanges || false}
                  primaryButtonText={"Ok"}
                  secondaryButtonText={"Cancel"}
                  hideCancel={false}
                  onConfirm={() => this.onModalConfirm()}
                  handleClose={(e) =>
                    this.setState({ isMessageModalOpen: false })
                  }
                />

                {this.state?.isConfirmMessageModalOpen && (
                  <MessageModal
                    open={this.state.isConfirmMessageModalOpen}
                    showCustom={true}
                    message={Message.deleteAdmin}
                    title={"Confirm Deletion"}
                    primaryButtonText={"Ok"}
                    secondaryButtonText={"Cancel"}
                    hideCancel={false}
                    onConfirm={() => this.onModalDeleteConfirm()}
                    handleClose={(e) =>
                      this.setState({ isConfirmMessageModalOpen: false })
                    }
                  />
                )}
                {this.state?.isErrorMessageModalOpen && (
                  <MessageModal
                    open={this.state.isErrorMessageModalOpen}
                    showCustom={true}
                    message={Message.deleteAdminError}
                    title={"Message"}
                    primaryButtonText={"Ok"}
                    hideCancel={true}
                    onConfirm={() => this.onErrorModalConfirm()}
                    handleClose={(e) =>
                      this.setState({ isErrorMessageModalOpen: false })
                    }
                  />
                )}
              </>
            </MDBContainer>
          </div>
        )}
      </div>
    );
  }
}

export default withUserContext(NewTenantShowEpisodes);
