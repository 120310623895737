import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import BasicTabs from "../../../components/SharedComponents/BasicTabs/BasicTabs";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import IconTextField from "../../../components/SharedComponents/IconTextField/IconTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableComponent from "../../../components/SharedComponents/Table";
import * as Constants from "../../../constants/constants";
import PostWatchService from "../../../services/service";
import { orgConfig, currencyList } from "./Config";
import "./Organisation.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
  validateNumbersOnly,
} from "../../../Common/Helper";
import MessageModal from "../../../components/SharedComponents/MessageModal";
import Message from "../../../Common/Messages.json";

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInitiated: false,
      renderList: [],
      isSubmit: false,
      isLoading: false,
      tableListSorted: [],
      page: 1,
      rowsPerPage: 10,
      totalRows: 0,
      sortBy: null,
      sortOrder: "asc",
      a_z: false,
      config: JSON.parse(JSON.stringify({ ...orgConfig })),
      isMessageModalOpen: false,
      isErrorMessageModalOpen: false,
    };
  }
  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };

  componentDidMount() {
    this.getOrganizationDetails();
  }

  handleInlineEdits = (id, dataItem) => {
    let seasonDetails = this.state.renderList;
    this.setState({
      renderList: seasonDetails.map(
        (item) => {
          if (item.lookup_data_id === id) item["editing"] = true;
          return item;
        },
        () => {
          console.log("handleInlineEdit", this.state.renderList);
        }
      ),
    });
  };

  saveInlineEdits = (obj, index) => {
    let data = { ...obj[index] };
    data.is_active = data.is_active ? 0 : 1;
    if (this.validateBeforeSave(data)) {
      this.postData(data);
    }
  };

  validateBeforeSave = (exp) => {    
    let canSubmit =
    (exp?.lookup_code?.length > 0 && exp?.lookup_value?.length > 0 &&
    (exp?.is_active === 0 || 1)) ?? false;
    return canSubmit;
  };


  postData = (data) => {
    this.setState({ isPosting: true });
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl +
        `/postlookupdata?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id,
      1
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          this.setState({
            isErrorMessageModalOpen: true,
            isMessageModalOpen: false,
            isPosting: false,
          });
        } else {
          this.setState({
            isMessageModalOpen: false,
            isPosting: false,
            postInitiated: false,
          });
          this.getOrganizationDetails();
          this.deleteItem = null;
        }
      },
      (err) => {
        this.setState({ isPosting: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  getOrganizationDetails = () => {
    let filters = { ...this.state.filters };
    filters.page_count = this.state.rowsPerPage;
    filters.page_no = this.state.page;
    // filters.sort_by = this.state.sortBy;
    // filters.sort_order = this.state.sortOrder;
    this.setState({
      isLoading: true,
      filters,
    });
    PostWatchService.getDataWithPath(
      Constants.postWatchServiceBaseUrl +      
        "/getlookupdata?objectType=organization&pageNo=" +
        filters.page_no +
        "&pageCount=" +
        filters.page_count +
        "&sortBy=" +
        this.state.sortBy +
        "&sortOrder=" +
        this.state.sortOrder,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let tableResults = res?.data?.results?.map((obj) => {
          obj.is_active = obj.is_active ? 0 : 1;
          return obj;
        });
        let expenditureDetails = tableResults;
        this.setState({
          renderList: expenditureDetails,
          tableListSorted: tableResults,
          totalRows:res?.data?.total_records_count,
          isLoading: false,
          isSubmit: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false, isSubmit: false });
      });
  };

  getPosition = (val) => {
    switch (val) {
      case "Inactive":
        return 0;
      case "Code":
        return 1;
      case "Description":
        return 2;
    }
  };

  getColumnName = (val) => {
    switch (val) {
      case "Inactive":
        return "is_active";
      case "Code":
        return "lookup_code";
      case "Description":
        return "lookup_value";
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    let order = null;
    let config = JSON.parse(JSON.stringify(this.state?.config));
    if (this.state.a_z && config?.headings[Position]?.icon != "") {
      if (config?.headings[Position]?.icon == "sort-alpha-up") {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-down";
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-up";
      }
      order = "desc";
      this.setState({ a_z: false });
    } else {
      config?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      config.headings[Position].icon = "sort-alpha-down";
      order = "asc";
    }
    this.setState(
      { sortBy: col_name, sortOrder: order, config, page: 1 },
      () => {
        this.getOrganizationDetails();
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (this.validateBeforeSave(newDataItem)) {
      let list = [...this.state.renderList];
      let newData = {
        is_active: newDataItem?.is_active ? 0 : 1,
        lookup_code: newDataItem?.lookup_code,
        lookup_value: newDataItem?.lookup_value,
      };
      list.push(newData);
      this.setState({
        renderList: list,
        postInitiated: true,
      });
      newData.lookup_data_id=null;
      newData.lookup_type='Organization';
      newData.tenant_id = this.props?.userContext?.active_tenant?.tenant_id ;
      this.postData(newData);
      //{"lookup_data_id":1,"object_type":"Version","lookup_value":"Cable NTSC test 2","tenant_id":2,"is_active":1}
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => {
      this.getOrganizationDetails();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        page: 1,
      },
      () => {
        this.getOrganizationDetails();
      }
    );
  };

  handleDelCallBack = (id, dataItem) => {
    this.deleteItem = dataItem;
    this.setState({ isMessageModalOpen: true });
  };

  onModalConfirm = () => {
    let item = { ...this.deleteItem };
    item.is_deleted = 1;
    this.postData(item);
  };
  onErrorModalConfirm = () => {
    this.setState({
      isErrorMessageModalOpen: false,
    });
    this.deleteItem = null;
  };

  render() {
    let isLoading = this.state.isPosting;
    return (
      <div>
        <MDBContainer className="currencyContainer">
          <MDBRow className="table-striped">
            {isLoading ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (
              <>
                <TableComponent
                  list={this.state?.tableListSorted || []}
                  className={"clearencRecord"}
                  config={this.state?.config}
                  isLoading={this.state?.isLoading || false}
                  sortCallback={this.sortColumn}
                  stickyHeader={true}
                  handleAddNewItem={this.handleAddNewItem.bind(this)}
                  editCallback={this.handleInlineEdits.bind(this)}
                  saveCallback={this.saveInlineEdits.bind(this)}
                  addItemToList={true}
                  showMandatory={this.state.postInitiated}
                  handleDelCallBack={this.handleDelCallBack}
                />
                <div className="Pagination">
                  <TablePagination
                    component="div"
                    count={this.state?.totalRows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    page={this.state?.page - 1}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state?.rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </MDBRow>
        </MDBContainer>
        {this.state?.isMessageModalOpen && (
          <MessageModal
            open={this.state.isMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdmin}
            title={"Confirm Deletion"}
            primaryButtonText={"Ok"}
            secondaryButtonText={"Cancel"}
            hideCancel={false}
            onConfirm={() => this.onModalConfirm()}
            handleClose={(e) => this.setState({ isMessageModalOpen: false })}
          />
        )}
        {this.state?.isErrorMessageModalOpen && (
          <MessageModal
            open={this.state.isErrorMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdminError}
            title={"Message"}
            primaryButtonText={"Ok"}
            hideCancel={true}
            onConfirm={() => this.onErrorModalConfirm()}
            handleClose={(e) =>
              this.setState({ isErrorMessageModalOpen: false })
            }
          />
        )}
      </div>
    );
  }
}

export default withUserContext(Organization);
