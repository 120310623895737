import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import { withUserContext } from "../../contexts/UserContext";
import SelectField from "../SharedComponents/SelectField/SelectField";
import "./PODetails.scss";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";


class PoetField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poLinesList: null,
      firstPart: 10006771,
      expenditureOptions: this.props?.expenditureList || [],
      org: null,
      orgOptions: this.props?.orgList || [],
      task: null,
      taskOptions: this.props?.taskList || [],
      poet_1:{ firstPart:'10006771',expenditure:this.props?.selectedExpenditure || '',org: this.props?.selectedOrg || this.findDefaultOrg() ,task: this.props?.selectedTask || this.props?.taskList?.[0]?.value},
      poet_2:{ firstPart:'10006771',expenditure:'',org: '' ,task:''},
    };
  }

  componentDidMount() {
    //this.setPoetValue();
  }
 
  componentDidUpdate(prevProps, prevState) {
    if (prevState.orgOptions !== this.state.orgOptions) {
      this.setState({
        orgOptions: this.props?.orgList,
      });
    }
    }

    findDefaultOrg=()=>{
      const defaultOrg = this.props?.orgList?.find(org=>org.lookup_code==='100.493.00')?.lookup_data_id;
      console.log(this.props?.orgList,defaultOrg);
      return defaultOrg;
    }

  poetValueChange = (field, value, poetCount) => {
  this.setState((prevState) => {
    const poetKey = `poet_${poetCount}`;    
    return {
      [poetKey]: {
        ...prevState[poetKey],
        [field]: value,
      },
    };
  });
}

  render() {
    return (
      <>
        <div className="fourPartField">
          <div className="poet_fields first">
          <BasicTextField
            label=""
            isMandatory={true}
            value={this.state[`poet_${this.props?.poetCount}`]?.firstPart} 
            onChange={(e) => { }
            }
          />
          </div>
        
          <div className="poet_fields">
          <SelectField
             value={this.state[`poet_${this.props?.poetCount}`]?.org}
            options={this.state.orgOptions?.map((item) => {
              return {
                value: item.lookup_data_id,
                label:  item.lookup_value+' - '+item.lookup_code,
                is_active: item.is_active
              };
            })}
            onChange={(e) =>
              this.poetValueChange('org', e.target.value, this.props?.poetCount)
            }
            disabled={this.state.poHeaders?.is_void}
            placeHolderText={"Select"}
          />
          </div>
          <div className="poet_fields">
          <SelectField
             value={this.state[`poet_${this.props?.poetCount}`]?.expenditure}            options={this.state.expenditureOptions?.map((item) => {
              return {
                value: item.lookup_data_id,
                label: item.lookup_value+' - '+item.lookup_code,
                name:item.lookup_code,
                is_active: item.is_active
              };
            })}
            onChange={(e) =>{
              this.poetValueChange('expenditure', e.target.value, this.props?.poetCount)
            }
            }
            disabled={this.state.poHeaders?.is_void}
            placeHolderText={"Select"}
          />
          </div>
          <div className="poet_fields last">
          <SelectField
            value={this.state[`poet_${this.props?.poetCount}`]?.task}
            options={this.state.taskOptions}
            // options={this.state.taskOptions?.map((item) => {
            //   return {
            //     value: item.lookup_data_id,
            //     label: item.lookup_code,
            //     is_active: item.is_active
            //   };
            // })}
            onChange={(e) =>
              this.poetValueChange('task', e.target.value, this.props?.poetCount)
            }
            disabled={this.state.poHeaders?.is_void}
            placeHolderText={"Select"}
          />
 </div>
 <MDBIcon
          className="cursor-pointer ml2"
          aria-controls="simple-menu"
          aria-haspopup="true"
          color={"primary"}
          fontSize={10}
          onClick={() => this.props.poetFieldData(this.state[`poet_${this.props?.poetCount}`],this.props?.poetCount)}
          icon={"save"}
          
        />
        <MDBIcon
          className="cursor-pointer ml2"
          aria-controls="simple-menu"
          aria-haspopup="true"
          color={"primary"}
          fontSize={10}
          onClick={() => this.props.cancelPoetFieldData(this.state[`poet_${this.props?.poetCount}`],this.props?.poetCount)}
          icon={"times"}
          
        />
        </div>
     
      </>
    );
  }
}

export default withUserContext(PoetField);