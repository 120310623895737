import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import _ from "lodash";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBTooltip,
} from "mdb-react-ui-kit";
import { withUserContext } from "../../contexts/UserContext";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicTextArea from "../SharedComponents/BasicTextArea/BasicTextArea";
import TableComponent from "../SharedComponents/Table";
import "./PODetails.scss";
import {
  optionalRightsConfig,
  optionalRightsList,
  NewPOHeader,
  NewPOLine,
  revised,
} from "./FileMakerConfig";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import Popover from "@material-ui/core/Popover";
import axios from "axios";
import Modal from "../SharedComponents/Modal/Modal";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import DateField from "../SharedComponents/DateField/DateField";
import PoetField from "./PoetField";
import NotificationComponent from "../../Common/NotificationComponent/NotificationComponent"

let PONo;
class FileMakerPODetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poLinesList: null,
      isFetchingPOLines: false,
      showToolTip: false,
      tooltipElement: null,
      tooltipItem: null,
      poHeaders: null,
      NewPOHeader: { ...NewPOHeader },
      episodes: props.episodes,
      setUnits: null,
      isFetchingSetUnits: false,
      accountDetails: null,
      isFetchingAccountDetails: false,
      currencies: null,
      isFetchingCurrencies: false,
      isFetchingPoHeaders: false,
      searchCancelToken: null,
      selectedVendor: null,
      vendorsList: null,
      selectedVendorLocation: null,
      selectedVendorLocationList: null,
      totalCost: null,
      postInitiated: false,
      anchorEl: null,
      openPopover: false,
      selectedNoteRow: null,
      selectedNoteMessage: null,
      poRangeData: null,
      poErrormsg: false,
      activPORangeList: null,
      selectedTemplate: null,
      selectedTemplatePONumber: null,
      isFetchingShowReport: false,
      showOrientationModal: false,
      reportData: null,
      defaultCompanyExchangeRate: null,
      defaultCompanyCurrency: null,
      subtotalCost: null,
      totalCostWithoutCurrency: null,
      remainingCost: null,
      editPoet1: false,
      editPoet2: false,
      versionList: [],
      versionFormatList: [],
      versionLanguageList: [],
      expenditureList: [],
      orgList: [],
      taskList: [],
      selectedVendorContact: null,
      selectedVendorContactList: [],
      vendorExpand:false,
      shipToExpand:false,
      shipToVendorsList:null,
      selectedShipToVendor: null,
      selectedShipToVendorLocation: null,
      selectedShipToVendorLocationList: null,
      selectedShipToVendorContact: null,
      selectedShipToVendorContactList: [],
      validateS4code:false,
      clearVendor:false,
      clearShipTo:false,
      poSuccess:false,
      savedVendor:false,
      lastSavedVendor:[],
      lastSavedShipTo:[]
    };
  }

  componentDidMount() {
    optionalRightsConfig.headings[8].selectOptions = revised;
    // -1 for New PO creation
    if (this.props?.poOrder === -1) {
      let poHeaderNew = { ...NewPOHeader };
      poHeaderNew.show_season_id = this.props?.selectedSeason?.show_season_id;
      poHeaderNew.episode_id = this.props?.selectedEpisode?.episode_id;
      poHeaderNew.po_prefix = this.props?.preFix;
      poHeaderNew.s4_code = this.props.selectedSeason?.s4_code;
      this.setState({ poHeaders: poHeaderNew },()=>{
        this.validateS4Code(this.state.poHeaders?.s4_code);
      });
    } else {
      this.getPOLines(this.props?.poOrder?.po_id);
      // If in PO is void then we dont have to show edit and delete icons
      if (this.props?.poOrder?.is_void === 1) {
        optionalRightsConfig.actions = ["", "file", "info-circle", "", ""];
      } else {
        optionalRightsConfig.actions = ["copy", "file", "info-circle", "pen", "trash-alt"];
      }
      let selectedVendorLocation = _.find(
        this.props?.poOrder?.vendor_locations,
        { vendor_location_id: this.props?.poOrder?.po_vendor_location_id }
      );
      let selectedShipToVendorLocation = _.find(
        this.props?.poOrder?.ship_to_locations,
        { vendor_location_id: this.props?.poOrder?.po_ship_to_location_id }
      );
      this.setState({ poHeaders: { ...this.props?.poOrder } }, () => {        
        this.validateS4Code(this.state.poHeaders?.s4_code);
        this.setState({         
            lastSavedVendor: {
              vendor_name: this.state.poHeaders?.vendor_name,
              vendor_id: this.state.poHeaders?.vendor_id,
              vendor_locations: this.state.poHeaders?.vendor_locations,
              vendor_contacts: this.state.poHeaders?.vendor_contacts,
              po_vendor_location_id:this.state.poHeaders?.po_vendor_location_id,
              po_vendor_contact_id:this.state.poHeaders?.po_vendor_contact_id
            },
            lastSavedShipTo:{
              vendor_name: this.state.poHeaders?.ship_to_name,
              vendor_id: this.state.poHeaders?.ship_to_id,
              vendor_locations: this.state.poHeaders?.ship_to_locations,
              vendor_contacts: this.state.poHeaders?.ship_to_contacts,
            },
          selectedVendor: {
            vendor_name: this.state.poHeaders?.vendor_name,
            vendor_id: this.state.poHeaders?.vendor_id,
            vendor_locations: this.state.poHeaders?.vendor_locations,
            vendor_contacts: this.state.poHeaders?.vendor_contacts,
          },
          selectedShipToVendor: {
            vendor_name: this.state.poHeaders?.ship_to_name,
            vendor_id: this.state.poHeaders?.ship_to_id,
            vendor_locations: this.state.poHeaders?.ship_to_locations,
            vendor_contacts: this.state.poHeaders?.ship_to_contacts,
          },          
          selectedVendorLocationList: this.state.poHeaders?.vendor_locations,
          selectedVendorLocation,
          selectedVendorContact:this.setVendorContact(),
          selectedVendorContactList: this.state.poHeaders?.vendor_contacts,
          selectedShipToVendorLocationList: this.state.poHeaders?.ship_to_locations,
          selectedShipToVendorLocation,
          selectedShipToVendorContact: this.setShipToVendorContact(),
          selectedShipToVendorContactList:  this.state.poHeaders?.ship_to_contacts,
        });
      });
      
    }
    ;
    // this.getSetUnits();
    // this.getChartOfAccountDetails();   
    this.getMyVendors();
    this.getFormat();
    this.getLanguage();
    this.getExpenditure();
    this.getOrg();
    this.getTask();
    this.props.userContext?.getPORange(
      this.props?.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    );
    this.props.userContext?.getTemplates(
      this.props.show_id,
      this.props.selectedSeason?.season_id,
      this.props?.userContext?.user_profile?.user_id
    );
    //set default currency from prod company
    let defaultCompanyCurrencyItem = _.find(this.props.companies, {
      prod_company_id: this.props?.poOrder.prod_company_id,
    });
    this.setState({
      defaultCompanyExchangeRate: defaultCompanyCurrencyItem?.exchange_rate,
      defaultCompanyCurrency: defaultCompanyCurrencyItem?.currency_code + " (" + defaultCompanyCurrencyItem?.exchange_rate + ")"
    })

  }

  setVendorContact() {
    return this.state.poHeaders?.vendor_contacts?.filter(contact => contact.vendor_contact_id === this.state.poHeaders?.po_vendor_contact_id)?.[0];
}

setShipToVendorContact(){
  return this.state.poHeaders?.ship_to_contacts?.filter(contact => contact.vendor_contact_id === this.state.poHeaders?.po_ship_to_contact_id)?.[0];
   
  }

  editPoet(count) {
    if (count == 1) {
      this.setState({ editPoet1: true });
    } else if (count == 2) {
      this.setState({ editPoet2: true });
    }

  }

  showVendorContactDetail = () => {
    const { selectedVendorContact } = this.state;

    return (
        <div>
            {selectedVendorContact?.contact_name && (
                <div className="f-65rem"><span className="c-black f-75rem">Name: </span>{selectedVendorContact.contact_name}</div>
            )}
            {selectedVendorContact?.contact_phone && (
                <div className="f-65rem"><span className="c-black f-75rem">Phone: </span>{selectedVendorContact.contact_phone}</div>
            )}
            {selectedVendorContact?.contact_fax && (
                <div className="f-65rem"><span className="c-black f-75rem">Fax: </span>{selectedVendorContact.contact_fax}</div>
            )}
            {selectedVendorContact?.contact_email && (
                <div className="f-65rem"><span className="c-black f-75rem">Email: </span>{selectedVendorContact.contact_email}</div>
            )}
        </div>
    );
}

  showShipToVendorContactDetail=()=>{
    const { selectedShipToVendorContact } = this.state;
    return (
      <div>
      {selectedShipToVendorContact?.contact_name && (
          <div className="f-65rem"><span className="c-black f-75rem">Name: </span>{selectedShipToVendorContact.contact_name}</div>
      )}
      {selectedShipToVendorContact?.contact_phone && (
          <div className="f-65rem"><span className="c-black f-75rem">Phone: </span>{selectedShipToVendorContact.contact_phone}</div>
      )}
      {selectedShipToVendorContact?.contact_fax && (
          <div className="f-65rem"><span className="c-black f-75rem">Fax: </span>{selectedShipToVendorContact.contact_fax}</div>
      )}
      {selectedShipToVendorContact?.contact_email && (
          <div className="f-65rem"><span className="c-black f-75rem">Email: </span>{selectedShipToVendorContact.contact_email}</div>
      )}
  </div>
    );
  }

  getPOLines = (id) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getpolineitems?poId=" + id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            poLinesList: response.data,
            isFetchingPOLines: false,
          },
          () => {
            this.calcaluteTotalCost();
            this.getCurrencies();
            this.calculateSubtotal();
          }
        );
      },
      (err) => {
        this.setState({
          isFetchingPOLines: false,
        });
      }
    );
  };

  getCurrencies = () => {
    this.setState({
      isFetchingCurrencies: true,
    });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
      "/getshowcurrencies?showId=" +
      this.props.show_id,
      '',
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let currencyDetails = res.data;
        let option = currencyDetails.map((item) => {
          return {
            label: item.currency_code,
            value: item.currency_code,
          };
        });
        optionalRightsConfig.headings[5].selectOptions = option;
        this.setState({
          currencies: currencyDetails,
          isFetchingCurrencies: false,
        }, () => {
          // this.state?.poLinesList ?  this.updateUnavailableCurrency() :
          // setTimeout(this.updateUnavailableCurrency(),3000)

        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingCurrencies: false, isSubmit: false });
      });
  };

  updateUnavailableCurrency() {
    this.state?.poLinesList?.map(poItem => {
      const currencyNotFound = optionalRightsConfig?.headings[6]?.selectOptions?.find(currencyitem => currencyitem?.value == poItem?.currency_exchange);
      currencyNotFound == undefined && optionalRightsConfig.headings[5].selectOptions.push(
        { label: poItem?.currency_exchange, value: poItem?.currency_exchange, is_active: 0 })
    });
  }

  getMyVendors = () => {
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
      `/getuservendors?userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props.location?.state?.show_id}&pageNo=null&pageCount=null`, '',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          vendorsList: response.data.records,
          shipToVendorsList: response.data.records
        });
      },
      (err) => { }
    );
  };

  getFormat = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      '/getlookupdata?objectType=format',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          versionFormatList: response.data?.results,
        });
      },
      (err) => { }
    );
  }

  getLanguage = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      '/getlookupdata?objectType=language',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          versionLanguageList: response.data?.results,
        });
      },
      (err) => { }
    );
  }

  getExpenditure = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      '/getlookupdata?objectType=expenditure&sortBy=lookup_value&sortOrder=asc',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          expenditureList: response.data?.results,
        });
      },
      (err) => { }
    );
  }

  getOrg = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      '/getlookupdata?objectType=organization',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          orgList: response.data?.results,
        });
      },
      (err) => { }
    );
  }

  getTask = () => {
    let taskList = [{
      value: this.props.selectedSeason?.task,
      label: (this.props.selectedSeason?.task_description && this.props.selectedSeason?.task)
        ? `${this.props.selectedSeason?.task_description} - ${this.props.selectedSeason?.task}`
        : (this.props.selectedSeason?.task || this.props.selectedSeason?.task_description || ''),
      description: this.props.selectedSeason?.task_description
    }];
    
    this.setState({taskList});
    // PostWatchService.getData(
    //   Constants.postWatchServiceBaseUrl +
    //   '/getlookupdata?objectType=task',
    //   this.props?.userContext?.active_tenant?.tenant_id
    // ).then(
    //   (response) => {
    //     this.setState({
    //       taskList: response.data,
    //     });
    //   },
    //   (err) => { }
    // );
  }

  getPORange = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getporangedetails?showSeasonId=" +
      this.props?.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            poRangeData: response.data,
          },
          () => {
            this.setActivePORange();
          }
        );
      },
      (err) => { }
    );
  };
  setActivePORange = () => {
    // Need to show only Active / Availble PO numbers
    let activePORange = _.filter(this.state.poRangeData, { is_avail: "1" });
    if (activePORange) {
      this.setState({ activPORangeList: activePORange });
    }
  };

  calculateSubtotal = () => {
    let subtotalCost = this.state.poLinesList?.reduce((accumulator, item) => {
      return accumulator + item.subtotal;
    }, 0);
    subtotalCost = Number.parseFloat(subtotalCost).toFixed(2);
    this.setState({ subtotalCost });
  }

  calcaluteTotalCost = () => {
    let totalCost = this.state.poLinesList?.reduce((accumulator, item) => {
      return accumulator + item.total_cost;
    }, 0);
    let totalCostWithoutCurrency = Number.parseFloat(totalCost).toFixed(2);
    totalCost = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(totalCost, totalCostWithoutCurrency);

    this.setState({ totalCost, totalCostWithoutCurrency });
  };

  currencyConvertion = () => {
    let remainingCost = this.state?.totalCostWithoutCurrency - this.state?.subtotalCost
    remainingCost = Number.parseFloat(remainingCost).toFixed(2);
    remainingCost = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(remainingCost, remainingCost);
    return remainingCost;
    //this.setState({ remainingCost : remainingCost});
  }

  updateActualCost=(actual_cost)=>{
    this.onPoHeaderChange('actual_cost', actual_cost);
  }

  saveInlineEdits = (data, index) => {
    if (this.validateBeforeSave(data[index])) {   
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      // let currencyItem = _.find(this.state.currencies, {
      //   currency_code: data[index].currency_code,
      // });

      // if (currencyItem) {
      //   data[index].adj_currency_id = currencyItem.currency_id;
      //   data[index].adj_curr_exch_rate = currencyItem.exchange_rate;
      // }
      data[index].is_copy = Number("0");
      this.postData(data[index]);
    }
  };

  postData = (data) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
      `/postpolineitem?userId=` +
      this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (!response.data.error) {
          this.setState({
            isFetchingPOLines: false,
            postInitiated: false,
          });
          this.getPOLines(this.state?.poHeaders?.po_id);
        } else {
          this.setState({ isFetchingPOLines: false });
        }
      },
      (err) => {
        this.setState({ isFetchingPOLines: false });
        console.log("Post polineitem error: " + err);
      }
    );
  };

  handleInlineEdits = (id, dataItem) => {
    let poLinesList = this.state.poLinesList;
    // Setunit and Acc are search select which require value and text to render in the table, so here we are building that object
    this.setState({
      poLinesList: poLinesList.map(
        (item) => {
          if (item.po_line_id === id) {
            item["editing"] = true;
            item["setunit_id"] = {
              value: item["setunit_id"],
              text: item["set_unit"],
            };
            item["chartofaccount_id"] = {
              value: item["chartofaccount_id"],
              text: item["acc_detail"],
            };
            if (!item?.currency_exchange) {
              item["currency_exchange"] = this.state.defaultCompanyCurrency;
              item["total_cost"] = Number.parseFloat(item.subtotal * (this.state.defaultCompanyExchangeRate ?? 1)).toFixed(2);
              item["total_cost_value"] = Number.parseFloat(item.subtotal * (this.state.defaultCompanyExchangeRate ?? 1)).toFixed(2);
            }
          }
          return item;
        },
        () => {
          console.log("handleInlineEdit", this.state.poLinesList);
        }
      ),
    });

  };

  handleCancelInlineEdits = () => {
    let poLinesList = this.state.poLinesList;
    this.setState({
      poLinesList: poLinesList.map((item) => {
        item["editing"] = false;
        return item;
      }),
    });
  };

  handleSyncPopupClick = () => { };
  handleClickEPSModal = () => { };

  validateBeforeSave = (newDataItem) => {
    // Check for required fields
    let canSubmit = true
      // newDataItem?.gl_number &&
      //  newDataItem?.chartofaccount_id &&
      // newDataItem?.unit_cost &&
      // newDataItem?.quantity;

    return canSubmit;
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (this.validateBeforeSave(newDataItem)) {
      newDataItem.po_id = this.state?.poHeaders?.po_id;
      newDataItem.episode_id = this.props?.selectedEpisode?.episode_id;
      newDataItem.subtotal = newDataItem.quantity * newDataItem.unit_cost;

      let currency = _.find(this.state.currencies, {
        currency_id: newDataItem?.adj_currency_id,
      });

      // if (currency?.exchange_rate) {
      //   newDataItem.total_cost = newDataItem.subtotal * currency.exchange_rate;
      //   newDataItem.total_cost = Number.parseFloat(
      //     newDataItem.total_cost
      //   ).toFixed(2);
      // }
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      // let currencyItem = _.find(this.state.currencies, {
      //   currency_exchange: newDataItem.currency_exchange,
      // });

      if (currency) {
        newDataItem.adj_currency_id = currency.currency_id;
        newDataItem.adj_curr_exch_rate = newDataItem.adj_curr_exch_rate ? newDataItem.adj_curr_exch_rate: currencyItem.exchange_rate;
      }

      // Setting default value which is "N"
      newDataItem.is_revised = newDataItem.is_revised
        ? newDataItem.is_revised
        : "N";

      optionalRightsConfig.isClearFields = true;
      this.postData(newDataItem);
    } else {
      // This will not clear already entered data
      optionalRightsConfig.isClearFields = false;
    }
  };

  poetFieldData(value, count) {
    //if(this.state.poHeaders?.poet_1_desc == null){
    const exp = this.state.expenditureList.find(item => item?.lookup_data_id == value?.expenditure) ?? '';
    const org = this.state.orgList.find(item => item?.lookup_data_id == value?.org) ?? '';
    const task = this.state.taskList.find(item => item?.value == value?.task) ?? '';
    const poet1Description = 
    (org?.lookup_value ? org?.lookup_value:'') + '\n' + 
    (exp?.lookup_value ? exp?.lookup_value:'') + '\n' +     
    (task.description ? task.description:'');
    const poet1 = value?.firstPart +  
    (org?.lookup_code ?   '.' +org.lookup_code:'') + (exp?.lookup_code ? '.' + exp.lookup_code :'') + (task?.value ? '.' +task.value :'');
    this.onPoHeaderChange(`poet_${count}_desc`, poet1Description);
    this.onPoHeaderChange(`poet_${count}`, poet1);
    this.onPoHeaderChange(`task`, value?.task);
    this.onPoHeaderChange(`org`, value?.org);
    this.onPoHeaderChange(`expenditure`, value?.expenditure);
    this.setState({ [`editPoet${count}`]: false })
  }

  cancelPoetFieldData(value, count){
    this.setState({ [`editPoet${count}`]: false });
  }

  handleOnHoverEnter = (e, data) => {
    // e.stopPropagation();
    // this.setState({
    //   tooltipElement: e.currentTarget,
    //   tooltipItem: data,
    //   showToolTip: true,
    // });
  };
  handleOnHoverLeave = (e) => {
    // e.stopPropagation();
    // this.setState({
    //   tooltipElement: null,
    //   tooltipItem: null,
    //   showToolTip: false,
    // });
  };
  
  validateS4Code = (s4_code) => {
    if (s4_code?.length > 0) {
      const regex = /^[A-Za-z]\d{2}\.\d{6}\.\d{2}\.\d{2}\.\d{2}$/;
      const validStatus = !regex.test(s4_code);
      this.setState({ validateS4code: validStatus });
    }
  }

  onPoHeaderChange = (field, value) => {
    this.setState({ poErrormsg: false });
    if(field==='s4_code' && value.length > 0){
     this.validateS4Code(value);
    }else{
      this.setState({validateS4code:false});
    }
    this.setState(
      (prevState) => ({
        poHeaders: {
          ...prevState.poHeaders,
          [field]: value,
        },
      })
    );
  };

  validatePOHeaders = (seasonData) => {
    const regex = /^D\d{2}\.\d{6}\.\d{2}\.\d{2}$/;
    let canSubmit = (this.state.poHeaders?.po_number && !this.state.validateS4code) || false;
   
    //let canSubmit = this.state.poHeaders?.po_number || false;
    return canSubmit;
  };

  savePoHeader = () => {
    if (this.validatePOHeaders()) {
      PONo = this.state.poHeaders?.po_number;
      this.setState({ isFetchingPoHeaders: true });
      PostWatchService.postData(
        Constants.postWatchServiceBaseUrl +
        `/postpoheader?userId=` +
        this.props?.userContext?.user_profile?.user_id,
        this.state.poHeaders,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data[0]?.po_id == 0) {
            this.props.userContext?.getPORange(
              this.props?.selectedSeason?.show_season_id,
              this.props?.userContext?.active_tenant?.tenant_id
            );
            this.setState({ poErrormsg: true, isFetchingPoHeaders: false });
          }
          else if (!response.data.error) {
            this.setState({
              isFetchingPoHeaders: false,
              poSuccess:true
            });
            this.getPOHeaders(
              this.props?.selectedEpisode?.episode_id,
              response.data[0]?.po_id
            );
          } else {
            this.setState({ isFetchingPoHeaders: false });
          }
        },
        (err) => {
          this.setState({ isFetchingPoHeaders: false });
          console.log("Post poHeaders error: " + err);
        }
      );
    }
  };

  getPOHeaders = (id, poId) => {
    this.setState({ isFetchingPOHeaders: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getpoheaders?episodeId=" +
      id +
      "&showSeasonId=" +
      this.props.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        // For newly created PO po_id will be null so take if from response
        let POID = null;
        if (!this.state.poHeaders?.po_id) {
          POID = poId;
          // Get updated PO range label upon new PO created
          this.getPORangeLabel(this.props.selectedSeason?.show_season_id);
        } else {
          POID = this.state.poHeaders?.po_id;
        }
        let header = _.find(response.data, {
          po_id: POID,
        });
        if (header?.is_void) {
          optionalRightsConfig.actions = ["", "file", "info-circle", "", ""];
        } else {
          optionalRightsConfig.actions = [
            "copy",
            "file",
            "info-circle",
            "pen",
            "trash-alt",
          ];
        }
        this.setState({
          poHeaders: header || {},
          isFetchingPOHeaders: false,
        }, () => {
          let defaultCompanyCurrencyItem = _.find(this.props.companies, {
            prod_company_id: this.state?.poHeaders?.prod_company_id,
          });
          this.setState({
            defaultCompanyExchangeRate: defaultCompanyCurrencyItem?.exchange_rate,
            defaultCompanyCurrency: defaultCompanyCurrencyItem?.currency_code + " (" + defaultCompanyCurrencyItem?.exchange_rate + ")"
          })
          this.getCurrencies();
        });
      },
      (err) => {
        this.setState({
          isFetchingPOHeaders: false,
        });
      }
    );
  };

  getPORangeLabel = (showSeasonId) => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getshowseasonporange?showSeasonId=" +
      showSeasonId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.props.userContext.setPoRangeLabel(response.data[0].po_range);
      },
      (err) => { }
    );
  };

  expandVendorField(edit){
    this.setState({vendorExpand: edit === 1? true:false})
    if(edit !==1 && this.state.clearVendor){
      this.onPoHeaderChange("po_vendor_location_id", null);
      this.onPoHeaderChange("po_vendor_contact_id", null);
      this.onPoHeaderChange("vendor_id", null);
      this.setState({clearVendor:false,
         selectedVendor: null, selectedVendorLocation: null,selectedVendorLocationList:null,selectedVendorContact:null,selectedVendorContactList:null,
         lastSavedVendor:null
       });
    }else{
      //this.resetVendor();
      this.setState({clearVendor:false});
    }
    if(edit === 0 && !this.state.clearVendor){
      this.setState({savedVendor:true});    
      this.setState({
        lastSavedVendor: {
          vendor_name: this.state.selectedVendor?.vendor_name,
          vendor_id: this.state.selectedVendor?.vendor_id,
          vendor_locations: this.state.selectedVendor?.vendor_locations,
          vendor_contacts: this.state.selectedVendor?.vendor_contacts,
          po_vendor_location_id:this.state.poHeaders?.po_vendor_location_id,
          po_vendor_contact_id:this.state.poHeaders?.po_vendor_contact_id
        },
        selectedVendorLocationList:this.state.selectedVendor?.vendor_locations,
        selectedVendorContactList:this.state.selectedVendor?.vendor_contacts,
      });
    }
    
  }

  resetVendor(){
    let selectedVendorLocation = _.find(
      this.state.lastSavedVendor?.vendor_locations,
      { vendor_location_id: this.state.lastSavedVendor?.po_vendor_location_id }
    );
    let selectedVendorContact = _.find(
      this.state.lastSavedVendor?.vendor_contacts,
      { vendor_contact_id: this.state.lastSavedVendor?.po_vendor_contact_id }
    );
    this.setState({
      selectedVendor: {
        vendor_name: this.state.lastSavedVendor?.vendor_name,
        vendor_id: this.state.lastSavedVendor?.vendor_id,
        vendor_locations: this.state.lastSavedVendor?.vendor_locations,
        vendor_contacts: this.state.lastSavedVendor?.vendor_contacts,
      },
       selectedVendorLocation: selectedVendorLocation,
       selectedVendorContact: selectedVendorContact,
       selectedVendorLocationList:this.state.lastSavedVendor?.vendor_locations,
       selectedVendorContactList:this.state.lastSavedVendor?.vendor_contacts,
       po_vendor_location_id:this.state.lastSavedVendor?.po_vendor_location_id,
       po_vendor_contact_id:this.state.lastSavedVendor?.po_vendor_contact_id
    });
    this.onPoHeaderChange("vendor_id", this.state.lastSavedVendor?.vendor_id);
    this.onPoHeaderChange("po_vendor_location_id", this.state.lastSavedVendor?.po_vendor_location_id);
    this.onPoHeaderChange("po_vendor_contact_id", this.state.lastSavedVendor?.po_vendor_contact_id);
  }
  
  resetShipTo(){
    let selectedShipToVendorLocation = _.find(
      this.state.lastSavedShipTo?.vendor_locations,
      { vendor_location_id: this.state.lastSavedShipTo?.po_ship_to_location_id }
    );
    let selectedShipToVendorContact = _.find(
      this.state.lastSavedShipTo?.vendor_contacts,
      { vendor_contact_id: this.state.lastSavedShipTo?.po_ship_to_contact_id }
    );
    this.setState({
      selectedShipToVendor: {
        vendor_name: this.state.lastSavedShipTo?.vendor_name,
        vendor_id: this.state.lastSavedShipTo?.vendor_id,
        vendor_locations: this.state.lastSavedShipTo?.vendor_locations,
        vendor_contacts: this.state.lastSavedShipTo?.vendor_contacts,
      },
      selectedShipToVendorLocation: selectedShipToVendorLocation,
      selectedShipToVendorLocationList: this.state.lastSavedShipTo?.vendor_locations,
      selectedShipToVendorContact: selectedShipToVendorContact,
      selectedShipToVendorContactList: this.state.lastSavedShipTo?.vendor_contacts,
    });
    this.onPoHeaderChange("vendor_id", this.state.lastSavedShipTo?.vendor_id);
    this.onPoHeaderChange("po_ship_to_location_id", this.state.lastSavedShipTo?.po_ship_to_location_id);
    this.onPoHeaderChange("po_ship_to_contact_id", this.state.lastSavedShipTo?.po_ship_to_contact_id);
  }

  clearVendorField=()=>{
   this.resetVendor();
   this.setState({ vendorExpand:false})
  }

  clearShipToField = () => {
    this.resetShipTo();

    // if (this.state.clearShipTo) {
    //   this.setState(
    //     (prevState) => ({
    //       poHeaders: {
    //         ...prevState.poHeaders,
    //       },        
    //       selectedShipToVendor: {
    //         vendor_name: this.state.poHeaders?.ship_to_name ?? this.state.selectedShipToVendor?.vendor_name,
    //         vendor_id: this.state.poHeaders?.ship_to_id,
    //         vendor_locations: this.state.poHeaders?.ship_to_locations,
    //         vendor_contacts: this.state.poHeaders?.ship_to_contacts,
    //       },
    //     })
    //   );
    // }
    this.setState({ shipToExpand:false})
  }

  expandShipTOField(edit){
    this.setState({shipToExpand: edit === 1? true:false})
    if(edit !==1 && this.state.clearShipTo){
      this.onPoHeaderChange("po_ship_to_location_id", null);
      this.onPoHeaderChange("po_ship_to_contact_id", null);
      this.onPoHeaderChange("ship_to_id",null);
      this.setState({clearShipTo:false,
        selectedShipToVendor: null, selectedShipToVendorLocation: null,selectedShipToVendorLocationList:null,
        selectedShipToVendorContact:null,selectedShipToVendorContactList:null,lastSavedShipTo:null
       });
    }else{
      this.setState({clearShipTo:false});
    }
    if(edit === 0 && !this.state.clearShipTo){
      this.setState({savedVendor:true});    
      this.setState({
        lastSavedShipTo: {
          vendor_id: this.state.selectedShipToVendor?.vendor_id,
          vendor_name: this.state.selectedShipToVendor?.vendor_name,
          vendor_locations: this.state.selectedShipToVendor?.vendor_locations,
          vendor_contacts: this.state.selectedShipToVendor?.vendor_contacts,
          po_ship_to_location_id:this.state.poHeaders?.po_ship_to_location_id,
          po_ship_to_contact_id:this.state.poHeaders?.po_ship_to_contact_id       
        },
        // selectedShipToVendorLocationList:this.state.selectedVendor?.vendor_locations,
        // selectedShipToVendorContactList:this.state.selectedVendor?.vendor_contacts,
      });
    }
    
  }

  onShipToVendorSelection = (item) => {
    let vendor = _.find(this.state.shipToVendorsList, { vendor_id: item?.value });
    if (vendor) {
      vendor = {
        ...{ vendor_id: item.value, vendor_name: item.text },
        ...{ vendor_locations: vendor?.vendor_locations },
        ...{ vendor_contacts: vendor?.vendor_contacts },
      };
      this.setState(
        {
          selectedShipToVendor: { ...vendor },
          selectedShipToVendorLocation: vendor?.vendor_locations?.[0],
          selectedShipToVendorLocationList: vendor?.vendor_locations,
          selectedShipToVendorContact: vendor?.vendor_contacts?.[0],
          selectedShipToVendorContactList: vendor?.vendor_contacts,
          clearShipTo:false
        },
        () => { }
      );
    
      this.onPoHeaderChange("ship_to_id", item?.value);
      this.onPoHeaderChange("po_ship_to_location_id", vendor?.vendor_locations?.[0]?.vendor_location_id);
      this.onPoHeaderChange("po_ship_to_contact_id", vendor?.vendor_contacts?.[0]?.vendor_contact_id);
    } else {
      this.setState({clearShipTo:true})
       this.setState({ selectedShipToVendor: null, selectedShipToVendorLocation: null,
        selectedShipToVendorLocationList:null,selectedShipToVendorContactList:null,
        selectedShipToVendorContact:null });
      // this.onPoHeaderChange("po_ship_to_location_id", null);
      // this.onPoHeaderChange("po_ship_to_contact_id", null);
      // this.onPoHeaderChange("ship_to_id",null);
      // this.onPoHeaderChange("po_ship_to_location_id", null);
      // this.onPoHeaderChange("po_ship_to_contact_id", null);
    }
  };

  onVendorSelection = (item) => {
    let vendor = _.find(this.state.vendorsList, { vendor_id: item?.value });
    if (vendor) {
      vendor = {
        ...{ vendor_id: item.value, vendor_name: item.text },
        ...{ vendor_locations: vendor.vendor_locations },
        ...{ vendor_contacts: vendor.vendor_contacts },
      };
      this.setState(
        {
          selectedVendor: { ...vendor },
          selectedVendorLocation: vendor?.vendor_locations?.[0],
          selectedVendorLocationList: vendor?.vendor_locations,
          selectedVendorContact: vendor?.vendor_contacts?.[0],
          selectedVendorContactList: vendor?.vendor_contacts,
          clearVendor:false
        },
        () => { }
      );
      this.onPoHeaderChange("vendor_id", item.value);
      this.onPoHeaderChange("po_vendor_location_id", vendor?.vendor_locations?.[0]?.vendor_location_id);
      this.onPoHeaderChange("po_vendor_contact_id", vendor?.vendor_contacts?.[0]?.vendor_contact_id);
    } else {
        this.setState({ clearVendor:true,
           selectedVendor: null, selectedVendorLocation: null,selectedVendorLocationList:null,
           selectedVendorContact:null,selectedVendorContactList:null
         });

      // this.setState({ selectedVendor: null, selectedVendorLocation: null,selectedVendorLocationList:null,selectedVendorContact:null,selectedVendorContactList:null });
      // this.onPoHeaderChange("po_vendor_location_id", null);
      // this.onPoHeaderChange("po_vendor_contact_id", null);
      // this.onPoHeaderChange("vendor_id", null);
      // this.onPoHeaderChange("po_vendor_location_id", null);
      // this.onPoHeaderChange("po_vendor_contact_id", null);
      
    }
  };

  searchVendors = (e,field) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (e.target.value !== null) {
      this.setState({ isFetchingVendors: true });
      PostWatchService.getDataWithCancel(
        Constants.postWatchServiceBaseUrl +
        `/getvendorsbyname?vendorName=${e.target.value}&userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props?.show_id}`,
        cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id,
        1
      ).then(
        (response) => {
          if(field==='VEN'){
            this.setState({
              vendorsList: response.data,
              isFetchingVendors: false,
            });
          }else{
            this.setState({
              shipToVendorsList: response.data,
              isFetchingVendors: false,
            });
          }
         
        },
        (err) => {
          this.setState({
            isFetchingVendors: false,
          });
        }
      );
    }
  };

  vendorLocationChange = (id) => {
    let location = _.find(this.state?.selectedVendor?.vendor_locations, {
      vendor_location_id: id,
    });
    if (location) {
      this.setState({ selectedVendorLocation: location });
    }
    this.onPoHeaderChange("po_vendor_location_id", id);
  };

  shipToLocationChange = (id) => {
    let location = _.find(this.state?.selectedShipToVendor?.vendor_locations, {
      vendor_location_id: id,
    });
    if (location) {
      this.setState({ selectedShipToVendorLocation: location });
    }
    this.onPoHeaderChange("po_ship_to_location_id", id);
  };
  

  vendorContactChange = (id) => {
    let contact = _.find(this.state?.selectedVendor?.vendor_contacts, {
      vendor_contact_id: id,
    });
    if (contact) {
      this.setState({ selectedVendorContact: contact });
    }
    this.onPoHeaderChange("po_vendor_contact_id", id);
  };

  shipToContactChange = (id) => {
    let contact = _.find(this.state?.selectedShipToVendor?.vendor_contacts, {
      vendor_contact_id: id,
    });
    if (contact) {
      this.setState({ selectedShipToVendorContact: contact });
    }
    this.onPoHeaderChange("po_ship_to_contact_id", id);
  };

  handleDelCallBack = (id, dataItem) => {
    let data = dataItem;
    data.is_deleted = 1;
    this.postData(data);
  };

  handleCommentBox = (e, data) => {
    let poLine = _.find(this.state?.poLinesList, {
      po_line_id: data.po_line_id,
    });
    this.setState({
      anchorEl: e.currentTarget,
      openPopover: true,
      selectedNoteRow: poLine,
      selectedNoteMessage: data.notes || null,
    });
  };
  handleOnNoteChange = (value) => {
    this.setState({ selectedNoteMessage: value });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, openPopover: false });
  };
  saveNotes = () => {
    let noteRow = JSON.parse(JSON.stringify(this.state.selectedNoteRow));
    noteRow.notes = this.state.selectedNoteMessage;
    noteRow.setunit_id = noteRow?.setunit_id?.value
      ? noteRow?.setunit_id?.value
      : noteRow?.setunit_id;
    noteRow.chartofaccount_id = noteRow?.chartofaccount_id?.value
      ? noteRow?.chartofaccount_id?.value
      : noteRow?.chartofaccount_id;
    this.postData(noteRow);
    this.handleClose();
  };

  onVoidClick = (value) => {
    let poHeaders = { ...this.state.poHeaders };
    poHeaders.is_void = value;
    /* If any row of the table is in edit mode, make it to normal mode.*/
    this.handleCancelInlineEdits();
    this.setState(
      { poHeaders, selectedTemplate: null, selectedTemplatePONumber: null },
      () => {
        this.savePoHeader();
      }
    );
  };

  handleTemplateChange = (field, value) => {
    if (field === "template") {
      let template = _.find(this.props?.userContext?.templateItems, {
        po_template_header_id: value,
      });
      if (template) {
        this.setState({ selectedTemplate: template });
      }
    }

    if (field === "ponumber") {
      if (value) {
        this.setState({ selectedTemplatePONumber: value });
      }
    }
  };
  loadTemplatePOLines = () => {
    if (this.state?.selectedTemplate 
     // && this.state?.selectedTemplatePONumber
    ) {
      this.getTemplatePOLines();
    }
  };

  getTemplatePOLines = () => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getpotemplates?poTemplateHeaderId=" +
      this.state.selectedTemplate.po_template_header_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        let poLinesList = JSON.parse(JSON.stringify(this.state.poLinesList));
        if (poLinesList === null) {
          poLinesList = [];
        }

        let templatePOLines = response.data;
        // Add these two propeties since they dont come with template po lines
        _.forEach(templatePOLines, (item) => {
          item.po_line_id = null;
          item.po_id = this.state.poHeaders?.po_id;
          item.episode_id = this.props?.selectedEpisode?.episode_id;
          //selectedSeason.episodes[0].episode_id
          // Add GL Number only if template po line does not have GL number
          if (!item.gl_number) {
            item.gl_number = this.state?.selectedTemplatePONumber;
          }
        });
        poLinesList = [...poLinesList, ...templatePOLines];
        this.postAllPOlineItems(poLinesList);
      },
      (err) => {
        this.setState({
          isFetchingPOLines: false,
        });
      }
    );
  };
  postAllPOlineItems = (data) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
      `/postallpolineitems?userId=` +
      this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (!response.data.error) {
          this.setState({
            isFetchingPOLines: false,
            postInitiated: false,
          });
          this.getPOLines(this.state?.poHeaders?.po_id);
        } else {
          this.setState({ isFetchingPOLines: false });
        }
      },
      (err) => {
        this.setState({ isFetchingPOLines: false });
        console.log("Post postallpolineitems error: " + err);
      }
    );
  }; 

  downloadShowReport = () => {
    this.setState({ isFetchingShowReport: true });
    let orientation = this.state.orientation ||'';-
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        `/getexportpodetails?reportName=po_report_p&userId=` +
        this.props?.userContext?.user_profile?.user_id +
        "&poId=" +
        this.state.poHeaders?.po_id,
      "",
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.downloadUrl(response.data, "Show");
        this.setState({
          isFetchingShowReport: false,
        });
      },
      (err) => {
        this.setState({
          isFetchingShowReport: false,
        });
      }
    );
  };

  downloadUrl = (url, reportName) => {
    window.open(url, "_blank");
  };
  
  handleInlineCopy = (copyRecord) => {
    if (this.validateBeforeSave(copyRecord)) {
      // Since these are searchtext they will return item with value and label from which we need to send only id
      // If dropdowns are not changed then take direct number, if dropdown are changed then we get objects so take ?.value
      copyRecord.setunit_id = copyRecord?.setunit_id?.value
        ? copyRecord?.setunit_id?.value
        : copyRecord?.setunit_id;
      copyRecord.chartofaccount_id = copyRecord?.chartofaccount_id?.value
        ? copyRecord?.chartofaccount_id?.value
        : copyRecord?.chartofaccount_id;
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      let currencyItem = _.find(this.state.currencies, {
        currency_exchange: copyRecord.currency_exchange,
      });

      if (currencyItem) {
        copyRecord.adj_currency_id = currencyItem.currency_id;
        copyRecord.adj_curr_exch_rate = currencyItem.exchange_rate;
      }
      copyRecord.is_copy = Number("1");
      this.postData(copyRecord);
    }
  }
  
  handleResetNotify = () => {
    this.setState({poSuccess: false });
}

  render() {
    let showToolTip = Boolean(this.state.showToolTip);
    let tableLoading =
      this.state.isFetchingSetUnits ||
      this.state.isFetchingAccountDetails ||
      this.state.isFetchingCurrencies;
    return (
      <>      
        <MDBRow className="mb-2">
        <NotificationComponent
          open={
            this.state.poSuccess
          }
          message={"PO Saved Successfully"}
          severity={"success"}
          handleResetNotify={this.handleResetNotify.bind(this)}
        />
          <MDBCol md={6}>
            <div className="f-85rem c-black">
              <span className="cursor-pointer" onClick={this.props.backToPO}>
                <ArrowBack className="me-2" />
                Back to Purchase Order Grid
              </span>
            </div>
          </MDBCol>
          <MDBCol
            md={6}
            className="d-flex justify-content-end align-items-center"
          >
            <BasicButton
              className="me-3"
              text={
                this.state.isFetchingShowReport ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "PDF"
                )
              }
              icon={"file"}
              onClick={this.downloadShowReport}
            />
            <BasicButton
              text={
                this.state.isFetchingPoHeaders ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Save"
                )
              }
              variant="contained"
              type="inline"
              icon={"save"} onClick={this.savePoHeader}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="">
              <MDBCardBody>
                <MDBCardText>
                  <MDBRow>
                    <MDBCol md={6} className="poContainer">
                      <div className="topRow">
                      <div className={!this.state.poHeaders?.po_id ? `poField` : `poFiledView`}>
                      <div className="f-75rem c-black">PO No.</div>
                      <div className="poNoContainer">
                        <span className="f-65rem c-black">
                          {this.props?.preFix}
                        </span>
                        {!this.state.poHeaders?.po_id ? (
                          <SelectField
                            className="sh-select"
                            value={this.state.poHeaders?.po_number || null}
                            disabled={this.state.poHeaders?.is_void}
                            options={this.props?.userContext?.poRangeActiveItems?.map(
                              (item) => {
                                return {
                                  value: item.po_number,
                                  label: item.po_number,
                                };
                              }
                            )}
                            placeHolderText="- Select -"
                            onChange={(e, value) =>
                              this.onPoHeaderChange(
                                "po_number",
                                e.target.value
                              )
                            }
                            showMandatory={true}
                            isMandatory={true}
                          />
                        ) : (
                          <span className="f-65rem c-black">
                            &nbsp;{this.state.poHeaders?.po_number}
                          </span>
                        )}
                      </div>
                      {this.state.poErrormsg && <p className="errorText">PO No.{PONo} Already Used</p>}

                      {this.state.poHeaders?.is_void ? (
                        <div className="f-75rem redStar mt-4">VOIDED</div>
                      ) : (
                        ""
                      )}
                      </div>
                   
                    <div className={!this.state.poHeaders?.po_id ? `titleField`: `titleFieldView`}>
                      <div className="f-75rem c-black">
                        Title
                      </div>
                      <BasicTextField
                        label=""
                        isMandatory={false}
                        value={this.state.poHeaders?.title}
                        onChange={(e) =>
                          this.onPoHeaderChange(
                            "title",
                            e.target.value
                          )
                        }
                      />
</div>
</div>
<div  className="bottomRow">
<div className="invField">
                      <div className="f-75rem c-black">
                        Vendor Invoice #
                      </div>
                      <BasicTextField
                        label=""
                        isMandatory={true}
                        limit={"50"}
                        value={this.state.poHeaders?.vendor_invoice_no}
                        onChange={(e) =>
                          this.onPoHeaderChange(
                            "vendor_invoice_no",
                            e.target.value
                          )
                        }
                      />
</div>
                      <div className="f-75rem c-black version">Version</div>

                      <div className="twoPartField">
                        <div className="version_fields first">
                          <SelectField
                            value={
                              this.state.poHeaders?.format || null
                            }
                            placeHolderText={"-Select Format-"}
                            options={this.state.versionFormatList?.map((item) => {
                              return {
                                value: item.lookup_data_id,
                                label: item.lookup_value,
                                is_active: item.is_active
                              };
                            })}
                            onChange={(e, value) =>
                              this.onPoHeaderChange(
                                "format",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="version_fields last">
                          <SelectField
                            value={
                              this.state.poHeaders?.language || null
                            }
                            placeHolderText={"-Select Language-"}
                            options={this.state.versionLanguageList?.map((item) => {
                              return {
                                value: item.lookup_data_id,
                                label: item.lookup_value,
                                is_active: item.is_active
                              };
                            })}
                            onChange={(e, value) =>
                              this.onPoHeaderChange(
                                "language",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  
</MDBCol>
                    


                    <MDBCol md={3} className="vendorField">
                      <div className="f-75rem c-black">Vendor</div>
                      <div className="inline">
                        {!this.state.vendorExpand &&
                          <div className="f-65rem c-black ">{this.state?.selectedVendor?.vendor_name
                            ?? '-NA-'}</div>
                        }
                      {this.state.vendorExpand && 
                      <SearchSelectField
                        className="first"
                        detail_selected={
                         !this.state.clearVendor && this.state?.selectedVendor?.vendor_id
                            ? {
                              value: this.state?.selectedVendor?.vendor_id,
                              text: this.state?.selectedVendor?.vendor_name,
                            }
                            : null
                        }
                        options={this.state.vendorsList?.map((item) => ({
                          text: item.vendor_name,
                          value: item.vendor_id,
                          is_active: item.is_active,
                          is_favourite: item.is_favourite,
                        }))}
                        loading={this.state.isFetchingVendors}
                        width="100%"
                        disabled={!this.state.vendorExpand}
                        multiple={false}
                        searchSelect={true}
                        searchText={(event) => {this.searchVendors(event,'VEN')}}
                        placeholder="- Type to search vendors -"
                        noOptionsText="No results"
                        valueSelected={(e, newValue) => {
                          this.onVendorSelection(newValue);
                        }}
                      />
  }
                      {!this.state.vendorExpand &&
                      <MDBIcon
                        className="cursor-pointer ml2"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color={"primary"}
                        fontSize={10}
                        onClick={() => this.expandVendorField(1)}
                        icon={"pen"}
                      />
  }
                      { this.state.vendorExpand && 
                      <>
                       <MDBIcon
                       className="cursor-pointer ml2"
                       aria-controls="simple-menu"
                       aria-haspopup="true"
                       color={"primary"}
                       fontSize={10}
                       onClick={() =>  this.expandVendorField(0)}
                       icon={"save"}
                     />
                        <MDBIcon
                          className="cursor-pointer ml2"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          color={"primary"}
                          fontSize={10}
                          onClick={() =>  this.clearVendorField(0)}
                          icon={"times"}
                        />
                        </>
                      }
                      </div>
                      { this.state.vendorExpand && (
                        <>
                          <SelectField
                            value={this.state.poHeaders?.po_vendor_location_id}
                            options={this.state?.selectedVendorLocationList?.map(
                              (item, index) => {
                                return {
                                  value: item.vendor_location_id,
                                  label: item.location_name ? item.location_name : `Location ${index}`,
                                };
                              }
                            )}
                            onChange={(e, value) =>
                              this.vendorLocationChange(e.target.value)
                            }
                            disabled={this.state.poHeaders?.is_void}
                            placeHolderText="Select Location"
                          />
                          

                          <SelectField
                            value={this.state.poHeaders?.po_vendor_contact_id}
                            options={this.state?.selectedVendorContactList?.map(
                              (item, index) => {
                                return {
                                  value: item.vendor_contact_id,
                                  label: item.contact_name ? item.contact_name : `Contact ${index}`,
                                };
                              }
                            )}
                            onChange={(e, value) =>
                              this.vendorContactChange(e.target.value)
                            }
                            disabled={this.state.poHeaders?.is_void}
                            placeHolderText="Select Contact"
                            className="last"
                          />
                            
                        </>
                      )}
                      {!this.state.vendorExpand && this.state.selectedVendorLocation
                            ?.location_address && (
                              <div className="f-65rem c-black ">
                                <span  className="f-75rem">Location: </span>
                                {
                                  this.state.selectedVendorLocation
                                    ?.location_address
                                }
                              </div>
                            )}
                      {!this.state.vendorExpand && this.state.selectedVendorContact && (
                              <div className="c-black ">
                                {
                                 this.showVendorContactDetail()
                                }
                              </div>
                            )}
                    </MDBCol>

                    <MDBCol md={3} className="mb-2 shipToField">
                      <div className="f-75rem c-black">
                        Ship To
                      </div>
                      <div className="inline">
                        {!this.state.shipToExpand &&
                          <div className="f-65rem c-black ">{this.state?.selectedShipToVendor?.vendor_name
                            ?? '-NA-'}</div>
                        }
                        {this.state.shipToExpand &&
                          <SearchSelectField
                            className="first"
                            detail_selected={
                              !this.state.clearShipTo && this.state?.selectedShipToVendor?.vendor_id
                                ? {
                                  value: this.state?.selectedShipToVendor?.vendor_id,
                                  text: this.state?.selectedShipToVendor?.vendor_name,
                                }
                                : null
                            }
                            options={this.state.shipToVendorsList?.map((item) => ({
                              text: item.vendor_name,
                              value: item.vendor_id,
                              is_active: item.is_active,
                              is_favourite: item.is_favourite,
                            }))}
                            loading={this.state.isFetchingVendors}
                            width="100%"
                            disabled={!this.state.shipToExpand}
                            multiple={false}
                            searchSelect={true}
                            searchText={(event) => { this.searchVendors(event, 'SHIP') }}
                            placeholder="- Type to search Ship To -"
                            noOptionsText="No results"
                            valueSelected={(e, newValue) => {
                              this.onShipToVendorSelection(newValue);
                            }}
                          />
                        }
                      {!this.state.shipToExpand &&
                      <MDBIcon
                        className="cursor-pointer ml2"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color={"primary"}
                        fontSize={10}
                        onClick={() => this.expandShipTOField(1)}
                        icon={"pen"}
                      />
  }
                      { this.state.shipToExpand && 
                      <>
                        <MDBIcon
                          className="cursor-pointer ml2"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          color={"primary"}
                          fontSize={10}
                          onClick={() =>  this.expandShipTOField(0)}
                          icon={"save"}
                        />
                        <MDBIcon
                          className="cursor-pointer ml2"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          color={"primary"}
                          fontSize={10}
                          onClick={() =>  this.clearShipToField(0)}
                          icon={"times"}
                        />
                        </>
                      }
                      </div>
                      { this.state.shipToExpand && (
                        <>
                          <SelectField
                            value={this.state.poHeaders?.po_ship_to_location_id}                           
                            options={this.state?.selectedShipToVendorLocationList?.map(
                              (item, index) => {
                                return {
                                  value: item.vendor_location_id,
                                  label: item.location_name  ? item.location_name : `Location ${index}`,
                                };
                              }
                            )}
                            onChange={(e, value) =>
                              this.shipToLocationChange(e.target.value)
                            }
                            disabled={this.state.poHeaders?.is_void}
                            placeHolderText="Select Location"
                          />
                         
                          <SelectField
                            value={this.state.poHeaders?.po_ship_to_contact_id}
                            options={this.state?.selectedShipToVendorContactList?.map(
                              (item, index) => {
                                return {
                                  value: item.vendor_contact_id,
                                  label: item.contact_name  ? item.contact_name  : `Contact ${index}`,
                                };
                              }
                            )}
                            onChange={(e, value) =>
                              this.shipToContactChange(e.target.value)
                            }
                            disabled={this.state.poHeaders?.is_void}
                            placeHolderText="Select Contact"
                            className="last"
                          />
                          
                        </>
                      )}
                       {!this.state.shipToExpand && this.state.selectedShipToVendorLocation
                            ?.location_address && (
                              <div className="c-black f-65rem"><span className="f-75rem">Location: </span>
                                {
                                  this.state.selectedShipToVendorLocation
                                    ?.location_address
                                }
                              </div> 
                            )}
                            
                           {!this.state.shipToExpand && this.state.selectedShipToVendorContact && (
                              <div className="c-black f-65rem">
                                {
                                 this.showShipToVendorContactDetail()
                                }
                              </div>
                            )}
                    </MDBCol>


                  </MDBRow>

                  <MDBRow>

                    <MDBCol md={4} className="mb-2">

                      <div className="f-75rem c-black">
                        POET #1
                      </div>
                      {( this.state.editPoet1) &&
                        <PoetField
                          poetCount={1}
                          poet_1={this.state.poHeaders?.poet_1}
                          poetFieldData={this.poetFieldData.bind(this)}
                          cancelPoetFieldData= {this.cancelPoetFieldData.bind(this)}
                          expenditureList={this.state.expenditureList}
                          orgList={this.state.orgList}
                          taskList={this.state.taskList}
                          selectedTask={this.state.poHeaders?.task}
                          selectedOrg={this.state.poHeaders?.org}
                          selectedExpenditure={this.state.poHeaders?.expenditure}
                          // selectedTaskDesc={this.state.poHeaders?.task_value}
                          // selectedOrgDesc={this.state.poHeaders?.org_value}
                          // selectedExpenditureDesc={this.state.poHeaders?.expenditure_value}
                        />
                      }
                      {(!this.state.editPoet1) &&
                        <MDBRow>
                          <MDBCol md={10}>
                            <BasicTextField
                              label=""
                              isMandatory={true}
                              value={this.state.poHeaders?.poet_1}
                              disabled={!this.state.editPoet1}
                            />
                          </MDBCol>
                          <MDBCol md={2}>
                            <MDBIcon
                              className="cursor-pointer ml2"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              color={"primary"}
                              fontSize={10}
                              onClick={() => this.editPoet(1)}
                              icon={"pen"}

                            />
                          </MDBCol>
                        </MDBRow>
                      }
                    </MDBCol>
                    <MDBCol md={4} className="mb-2">
                      <div className="f-75rem c-black">
                        POET #2
                      </div>
                      {/* {(this.state.poHeaders?.poet_1 == null || this.state.editPoet2) &&
                        <PoetField
                          poetCount={2}
                          poet_2={this.state.poHeaders?.poet_2}
                          poetFieldData={this.poetFieldData.bind(this)}
                          expenditureList={this.state.expenditureList}
                          orgList={this.state.orgList}
                          taskList={this.state.taskList}
                        />
                      } */}
                     
                        <MDBRow>
                          <MDBCol md={12}>
                            <BasicTextField
                              label=""
                              isMandatory={true}
                              value={this.state.poHeaders?.poet_2}
                              onChange={(e) =>
                                this.onPoHeaderChange(
                                  "poet_2",
                                  e.target.value
                                )
                              }
                             // disabled={this.state.poHeaders?.poet_2}
                            />
                          </MDBCol>
                          {/* <MDBCol md={2}>
                            <MDBIcon
                              className="cursor-pointer ml2"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              color={"primary"}
                              fontSize={10}
                              onClick={() => this.editPoet(2)}
                              icon={"pen"}

                            />
                          </MDBCol> */}
                        </MDBRow>
                      
                    </MDBCol>
                    <MDBCol md={4} className="mb-2">
                      <div className="f-75rem c-black">
                        S4 code
                      </div>
                      <BasicTextField
                        label=""
                        isMandatory={false}
                        limit={"50"}
                        inValidInput={"Invalid S4 code"}
                        fieldValid={this.state.validateS4code}
                        value={this.state.poHeaders?.s4_code}
                        onChange={(e) =>
                          this.onPoHeaderChange(
                            "s4_code",
                            e.target.value
                          )
                        }
                      />
                    </MDBCol>



                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={4}>
                      <div className="f-75rem c-black">POET #1 Description</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.poet_1_desc}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("poet_1_desc", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />
                    </MDBCol>
                    <MDBCol md={4}>
                      <div className="f-75rem c-black">POET #2 Description</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.poet_2_desc}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("poet_2_desc", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />
                    </MDBCol>
                    <MDBCol md={4}>
                    <div className="f-75rem c-black">Billing Address</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.billing_address}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("billing_address", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />                      
                    </MDBCol>

                  </MDBRow>

                  <MDBRow>

                  </MDBRow>
                  <MDBRow className="rowPaddingTop">

                    <MDBCol md={4}>
                      <div className="f-75rem c-black">CC</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.cc}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("cc", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />
                    </MDBCol>
                    <MDBCol md={4}>
                      <div className="f-75rem c-black">Notes (internal)</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.notes}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("notes", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />
                    </MDBCol>
                  </MDBRow>

                  {this.state.poHeaders?.po_id && 
                      <MDBRow className="rowPaddingTop">
                        <MDBCol md={3} className="mb-2">
                          <div className="f-75rem c-black">
                            Add from Template
                          </div>
                          <SelectField
                            placeHolderText={"- Select Template -"}
                            value={
                              this.state.selectedTemplate?.po_template_header_id
                            }
                            disabled={this.state.poHeaders?.is_void}
                            options={
                              this.props.userContext?.templateItems?.map(
                                (item) => ({
                                  label: item?.po_template_name,
                                  value: item?.po_template_header_id,
                                })
                              ) || []
                            }
                            onChange={(e, value) =>
                              this.handleTemplateChange(
                                "template",
                                e.target.value
                              )
                            }
                          />
                        </MDBCol>
                        {/* <MDBCol md={2} className="mb-2">
                          <div className="f-75rem c-black">
                            GL No. {"  "}
                            <Tooltip
                              title={
                                "Selected GL # will be added to all PO lines which does not have GL #'s"
                              }
                              placement="right"
                            >
                              <Button className="table-info-button">
                                <MDBIcon
                                  icon={"info-circle"}
                                  className="action-icon"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <SelectField
                            className=""
                            value={this.state.selectedTemplatePONumber}
                            disabled={this.state.poHeaders?.is_void}
                            options={optionalRightsConfig.headings[0].selectOptions?.map(
                              (item) => {
                                return {
                                  value: item.value,
                                  label: item.label,
                                };
                              }
                            )}
                            placeHolderText="- Select GL# -"
                            onChange={(e, value) =>
                              this.handleTemplateChange(
                                "ponumber",
                                e.target.value
                              )
                            }
                          />
                        </MDBCol> */}
                        <MDBCol md={2} className="mb-2">
                          <div className="f-75rem c-black">&nbsp;</div>
                          <BasicButton
                            variant="outlined"
                            disabled={
                              !this.state?.selectedTemplate
                             //  || !this.state?.selectedTemplatePONumber
                            }
                            text="Add"
                            onClick={() => this.loadTemplatePOLines()}
                          />
                        </MDBCol>
                        </MDBRow>
                  }
                  <MDBRow
                    className="mt-3 podetailtable hbopodetailtable"
                    id="podetailtable"
                  >
                    {this.state.poHeaders?.po_id && (
                      <MDBCol md={12}>
                        {tableLoading ? (
                          <div className="ContentLoader">
                            <CircularProgress />
                          </div>
                        ) : (
                          <>
                            <TableComponent
                              list={this.state?.poLinesList || []}
                              config={optionalRightsConfig}
                              isLoading={this.state.isFetchingPOLines}
                              tableMode={"light"}
                              customNoResultsMessage={"Add a line item"}
                              editCallback={this.handleInlineEdits.bind(this)}
                              saveCallback={this.saveInlineEdits.bind(this)}
                              handleAddNewItem={this.handleAddNewItem.bind(
                                this
                              )}
                              addItemToList={!this.state.poHeaders?.is_void}
                              handleOnHoverEnter={this.handleOnHoverEnter}
                              handleOnHoverLeave={this.handleOnHoverLeave}
                              handleDelCallBack={this.handleDelCallBack}
                              showMandatory={this.state.postInitiated}
                              handleCommentBox={this.handleCommentBox}
                              currencies={this.state.currencies}
                              showNotesTooltip={true}
                              autoCalculate={true}
                              handleInlineCopy={this.handleInlineCopy}
                              defaultCompanyExchangeRate={this.state.defaultCompanyExchangeRate}
                              defaultCompanyCurrency={this.state.defaultCompanyCurrency}
                              actualCost={this.state.poHeaders?.actual_cost}
                              totalCost={this.state.totalCost}
                              updateActualCost={this.updateActualCost}
                              remainingBalance={this.currencyConvertion}
                            />
                            <MDBRow>
                              <MDBCol md={6}>
                                <div class="float-left voidBtn">
                                  {this.state.poHeaders?.is_void ? (
                                    <BasicButton
                                      className="mt-2 cancel-void"
                                      variant="outlined"
                                      text="Cancel Void"
                                      onClick={() => this.onVoidClick(0)}
                                    />
                                  ) : (
                                    <BasicButton
                                      className="void-btn"
                                      variant="outlined"
                                      text="Void"
                                      onClick={() => this.onVoidClick(1)}
                                    />
                                  )}
                                </div>
                              </MDBCol>

                            </MDBRow>
                          </>

                        )}
                      </MDBCol>

                    )}
                  </MDBRow>


                  <MDBRow>
                    <MDBCol md={3} className="mb-3">
                      <div className="f-75rem c-black">
                        FileMaker Order Number #
                      </div>
                      <BasicTextField
                        label=""
                        isMandatory={true}
                        limit={"50"}
                        value={this.state.poHeaders?.order_no}
                        onChange={(e) =>
                          this.onPoHeaderChange(
                            "order_no",
                            e.target.value
                          )
                        }
                      />
                    </MDBCol>

                    <MDBCol md={3} className="mb-3">
                      <div className="f-75rem c-black">
                        EPR
                      </div>
                      <BasicTextField
                        label=""
                        isMandatory={true}
                        limit={"50"}
                        value={this.state.poHeaders?.epr_no}
                        onChange={(e) =>
                          this.onPoHeaderChange(
                            "epr_no",
                            e.target.value
                          )
                        }
                      />
                    </MDBCol>
                    <MDBCol md={3} className="mb-3">
                      <div className="f-75rem c-black">
                        EPR Tracking Date
                      </div>
                      <DateField
                        value={this.state?.poHeaders?.epr_tracking_date || null}
                        // width={"100%"}
                        onChange={(e) => {
                          this.onPoHeaderChange(
                            "epr_tracking_date",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          );
                        }}
                      />
                    </MDBCol>
                    <MDBCol md={3} className="mb-3">
                      {this.state.poHeaders?.created_at_text ?
                        <>
                          <div className="f-75rem c-black">Created On</div>
                          <div className="f-65rem">
                            {this.state.poHeaders?.created_at_text}
                          </div>
                          <div className="f-75rem c-black">Created By</div>
                          <div className="f-65rem">
                            {this.state.poHeaders?.full_name}
                          </div> </> : ''}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={6}>
                    <div className="f-75rem c-black">Shipping Instructions & Account Number</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.shipping_instructions}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("shipping_instructions", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />                    
                      </MDBCol>
                    <MDBCol md={6}>
                      <div className="f-75rem c-black">Special Instructions</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.special_instructions}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("special_instructions", e.target.value)
                        }
                        rows={3}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />
                      </MDBCol>
                  </MDBRow>
<MDBRow>
<MDBCol md={12} className="mb-3 rowPaddingTop">
                      <div className="f-75rem c-black">Additional Comments</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.additional_comments}
                        onChange={(e, value) =>
                          this.onPoHeaderChange("additional_comments", e.target.value)
                        }
                        rows={8}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                      />
                      </MDBCol>
</MDBRow>


                  <MDBRow>
                    <MDBCol md={12}>
                      {!this.state.poHeaders?.is_void && (
                        <Popover
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          id={"popover"}
                          open={this.state.openPopover}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose}
                        >
                          <MDBRow>
                            <MDBCol md={12}>
                              <BasicTextArea
                                width="300px"
                                className="polines-notes"
                                rows={3}
                                value={
                                  this.state.selectedNoteMessage || ""
                                }
                                onChange={(e) =>
                                  this.handleOnNoteChange(
                                    e.target.value
                                  )
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol
                              md={12}
                              className="d-flex justify-content-end"
                            >
                              <BasicButton
                                text={"Save"}
                                className="m-1"
                                onClick={() => this.saveNotes()}
                              />
                              <BasicButton
                                variant="outlined"
                                type="inline"
                                className="m-1"
                                text={"Close"}
                                onClick={() => this.handleClose()}
                              />
                            </MDBCol>
                          </MDBRow>
                        </Popover>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>       
      </>
    );
  }
}

export default withUserContext(FileMakerPODetails);