export const orgConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      icon: "",
      width: "10%",
      inputType: "checkbox",
    },
    {
      headingLabel: "Code",
      icon: "",
      inputType: "text",
      width: "40%",
    },
    {
      headingLabel: "Description",
      icon: "",
      inputType: "text",
      width: "40%",
    },
  ],
  dataNodes: ["is_active", "lookup_code","lookup_value"],
  primaryKey: "lookup_data_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
  inlineEdits: [
    {
      dataNode: "is_active",
      width: "9%",
    },  
    {
      dataNode: "lookup_code",
      width: "40%",
      isMandatory: true,
    },  
    {
      dataNode: "lookup_value",
      width: "40%",
      isMandatory: true,
    },
  ],
};
export const currencyList = [
 
];
