import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { MDBContainer } from "mdbreact";
import { MDBSpinner } from "mdb-react-ui-kit";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LandingPage from "./LandingPage/LandingPage";
import HeaderContent from "../header/HeaderContent";
//import FooterContent from '../components/PageElements/FooterContent';
import ErrorPage from "../error-boundary/ErrorPage";
//import "bootstrap-css-only/css/bootstrap.min.css";
import { withOktaAuth } from "@okta/okta-react";
import PostWatchService from "../services/service";
import * as Constants from "../constants/constants";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { UserContext } from "../contexts/UserContext";
import MainContent from "./MainContent";
import * as AppConstants from "../constants/appConstants";
import { AppService } from "../services/AppService";
import _ from "lodash";

export default withRouter(
  withOktaAuth(
    class Home extends Component {
      constructor(props) {
        super(props);
        if (this.props.authState.accessToken == undefined)
          //this.props.authService.logout('/');
          this.props.oktaAuth.signOut("/");
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
        this.state = {
          isValidUser: true,
          isLoading: true,
          isLoggedIn: false,
          user_name: null,
          user_full_name: null,
          user_profile: null,
          user_profile_okta: null,
          errorId: 0,
          errorDesc: "",
          active_tenant: {
            tenant_id: null,
            tenant_name: null,
            role: null,
            permissions: {
              isAdmin: false,
            },
          },
          active_route: "/",
          dropDownList: {
            setNames: [],
            show: [],
            idPreffixes: [],
            idSuffixes: [],
            category: [],
            condition: [],
            location: [],
          },
          userContacts: null,
          groupMembers: null,
          currentGroupMember: null,
          phoneTypes: null,
          appLoader: false,
          templateItems: [],
          poRangeItems: [],
          poRangeActiveItems: [],
          currentShowSelected: null,
          poRangeLabel: null,
          activePage: '/',
          activeTab: null
        };
      }

      _onIdle(e) {
        //authService.logout();
        this.props.oktaAuth.signOut("/");
        this.setState({
          isLoggedOut: true,
          errorId: "440",
          errorDesc: "Session is timed out. Please login again!",
        });
      }

      async componentDidMount() {
        try {
          this._isMounted = true;
          await this.props.oktaAuth.getUser().then(
            (response) => {
              let name = response.name;
              let email = response.email;

              //console.log("User Info :" + JSON.stringify(response));
              localStorage.setItem("user_email", email);
              //localStorage.setItem('user_name', name);
              localStorage.setItem("user_full_name", name);
              //localStorage.setItem('user_profile_okta', JSON.stringify(UserProfile));
              this.setState({ user_full_name: name }); //isLoading : false,

              PostWatchService.getData(
                Constants.postWatchServiceBaseUrl + "/getuserdetails",
                this.props?.userContext?.active_tenant?.tenant_id
              ) //"./user_data.json") //Constants.Set ManagerSharedServiceBaseUrl + 'User?userEmail=' + email, this.props.userContext?.active_tenant?.tenant_id)
                .then(
                  (response) => {
                    if (this._isMounted) {
                      //console.log("User Profile -->" + JSON.stringify(response));
                      if (response) {
                        let userProfile = response.data[0]; // UserProfile; // response.json(); //data[0];
                        let userId = userProfile.user_id;
                        const tenandId = userProfile.tenant_id;
                        localStorage.setItem("user_id", userId);
                        localStorage.setItem(
                          "user_profile",
                          JSON.stringify(userProfile)
                        );
                        let assignTenandId = {...this.state.active_tenant}
                        assignTenandId.tenant_id = tenandId;
                        this.setState({active_tenant : assignTenandId});
                        var is_user_provisioner =
                          userProfile.is_user_provisioner;
                        var is_active_user = userProfile.is_active;

                        if (!is_active_user) {
                          this.setState({
                            isValidUser: false,
                            isLoggedIn: false,
                            isLoading: false,
                            errorId: "401",
                            errorDesc:
                              "User is marked as inactive in the system. Please contact admin for Phone Log application",
                          });
                        } else {
                          if (is_user_provisioner) {
                            userProfile.default_tenant_id = 0;
                          }
                          this.setState({
                            isValidUser: true,
                            isLoading: false,
                            isLoggedIn: true,
                            user_name: userProfile.name,
                            user_profile: userProfile,
                          });
                        }
                        this.processPermissions(
                          userProfile,
                          is_user_provisioner
                        );
                      } else {
                        this.setState({
                          isValidUser: false,
                          isLoggedIn: false,
                          isLoading: false,
                          errorId: "401",
                          errorDesc:
                            "User is not available in the system or marked as inactive. Please contact admin for Phone Log application",
                        });
                      }
                    }
                  },
                  (err) => {
                    console.log(
                      "Service Error : Log In -> Getting User Profile " + err
                    );
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: "503",
                      errorDesc:
                        "User profile service is not available. Please try again and contact admin for Phone Log application",
                    });
                  }
                );
            },
            (err) => {
              console.log(err);
              this.setState({
                isValidUser: false,
                isLoggedIn: false,
                isLoading: false,
                errorId: "503",
                errorDesc:
                  "OKTA user profile service is not available. Please try again and contact admin for Phone Log application",
              });
            }
          );
        } catch (err) {}
      }
      processPermissions(userProfile, is_user_provisioner) {
        //let permissions = appConstants.PERMISSIONS;
        //let permissionObjs = [];
        if (userProfile) {
          // debugger;
          let tenants = [];
          //var default_tenant_id = 1; // userProfile.default_tenant_id;
          var default_tenant_id = userProfile?.tenant_id || 1;
          var active_tenant = localStorage.getItem("active_tenant_id");
          var active_tenant_name = localStorage.getItem("active_tenant_name");
          var active_tenant_role;
          // this.setState({ tenants: userProfile.last_logged_at });
          // userProfile.last_logged_at.forEach((tenant) => {
          this.setState({ tenants: userProfile.user_roles });
          userProfile?.user_roles?.forEach((tenant) => {
            if (tenant.tenant_id === null) {
              tenant.tenant_id = 0;
              tenant.tenant_name = "All Tenants";
            }
            tenants.push({
              tenant_id: tenant.tenant_id,
              tenant_name: tenant.tenant_name,
            });
           // localStorage.setItem("tenants", JSON.stringify(tenants));
            localStorage.setItem("tenants", tenants);

            if (
              (active_tenant === "null" || !active_tenant) 
              && default_tenant_id == tenant?.tenant_id || active_tenant != tenant?.tenant_id
            ) {
              localStorage.setItem("active_tenant_id", tenant.tenant_id);
              localStorage.setItem("active_tenant_name", tenant.tenant_name);
              active_tenant = tenant.tenant_id;
            }
            console.log(active_tenant,tenant);
            if (
              Number(active_tenant) === Number(tenant.tenant_id) ||
              Number(userProfile.is_user_provisioner) === 1
            ) {
              this.processPermissionsforTenant(tenant, is_user_provisioner);
            }
            //permissionObjs.push(permObj);
          });
          //localStorage.setItem("permissions", permissionObjs);
        }
        if (is_user_provisioner) {
          this.props.history.push("/usermanagement");
        }
      }

      processPermissionsforTenant(tenant, is_user_provisioner) {
        // var isAdmin = false;
        //var isViewData = false;
        //var isViewUser = false;

        // var active_tenant_name = localStorage.getItem("active_tenant_name");
        // var active_tenant_role;
        var active_tenant = { ...this.state.active_tenant };
        var active_tenant_id = tenant.tenant_id;
        var active_tenant_name = tenant.tenant_name;
        var active_tenant_role = tenant.role;

        // }
        // if(active_tenant === tenant.tenant_id){
       
        var permissions_tenant = active_tenant.permissions;

        var isAdmin =
          tenant?.role_name && tenant?.role_name?.toLowerCase() === "admin"
            ? true
            : false;
        var isRegular =
          tenant?.role_name && tenant?.role_name?.toLowerCase() === "user"
            ? true
            : false;
        var isReadOnly =
          tenant?.role_name &&
          tenant?.role_name?.toLowerCase() === "read-only user"
            ? true
            : false;

        tenant.permissions.map((permission) => {
          permissions_tenant.isAdmin = isAdmin;
          permissions_tenant.isRegular = isRegular;
          permissions_tenant.isReadOnly = isReadOnly;
          if (is_user_provisioner === 1) {
            permissions_tenant.isManageUser = true;
          }
          if (
            permission?.permission_name?.toUpperCase() ===
            AppConstants.LABEL_MANAGE_LOOKUP
          ) {
            permissions_tenant.isManageLookups = true;
          }
          if (permission.permission_name === "Edit Set") {
            permissions_tenant.isModifySet = true;
          }

          if (permission.permission_name === "View Set") {
            permissions_tenant.isViewSet = true;
          }

          if (is_user_provisioner) {
            active_tenant_role = "User Provisioner";
            // permissions_tenant.isViewUser = true;
            // permissions_tenant.isModifyUser = true;
          }
        });

        active_tenant.tenant_id = active_tenant_id;
        active_tenant.tenant_name = active_tenant_name;
        active_tenant.role = active_tenant_role;
        active_tenant.permissions = permissions_tenant;

        console.log(
          "User Profile - Active Tenant:" + JSON.stringify(active_tenant)
        );

        this.setState({
          // isAdmin: isAdmin,
          // isCreateShow: isCreateShow,
          // isCreateSeason: isCreateSeason,
          // isCreateEpisode: isCreateEpisode,
          // isCreateTalent: isCreateTalent,
          // isViewShow: isViewShow,
          // isViewSeason: isViewSeason,
          // isViewEpisode: isViewEpisode,
          // isViewTalent: isViewTalent,
          active_tenant: active_tenant,
        });

        localStorage.setItem("active_tenant_id", tenant.tenant_id);
        localStorage.setItem("active_tenant_name", tenant.tenant_name);
        // localStorage.setItem("active_tenant_event_role", eventRole);
        // localStorage.setItem("active_tenant_gift_role", giftRole);
        // localStorage.setItem("active_tenant_card_role", cardRole);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }

      switchTenant = (tenant_id) => {
        //alert("Switched to Tenant -->"+tenant_id);
        var tenant = this.state.user_profile.user_roles.find(
          (tenant) => tenant.tenant_id === tenant_id
        );
        this.processPermissionsforTenant(
          tenant,
          this.state.user_profile.is_user_provisioner
        );
        this.props.history.push("/", this.state);
        window.location.reload();
      };

      updateShows = (shows) => {
        this.setState({ shows });
        AppService.updateShows(shows);
      };
      setCurrentGroupMember = (id) => {
        var obj = this.state.groupMembers.filter((obj) => obj.user_id === id);
        this.setState({ currentGroupMember: obj[0] });
      };
      setGroupMembers = (obj) => {
        this.setState({ groupMembers: obj, currentGroupMember: obj[0] });
      };

      setAppLoader = (value) => {
        this.setState({ appLoader: value });
      };
      updateLookUpData = (lookupType) => {
        AppService.updateLookupData(lookupType);
      };

      setTemplateItems = (data) => {
        this.setState({ templateItems: data });
      };

      getShows = () => {
        PostWatchService.getData(
          Constants.postWatchServiceBaseUrl +
            "/getshows" +
            this.state?.active_tenant?.tenant_id
        ).then(
          (response) => {
            this.updateShows([...response.data]);
          },
          (err) => {
            this.setState({ isLoading: false });
            console.log("Error in fetching user contacts", err);
          }
        );
      };

      setPhoneTypes = (data) => {
        this.setState({ phoneTypes: data });
      };
      getTemplates = (show_id, seasonId, user_id) => {
        PostWatchService.getDataParams(
          Constants.postWatchServiceBaseUrl +
            "/getuserpotemplates?showId=" +
            show_id +
            "&seasonId=" +
            seasonId +
            "&userId=" +
            user_id,
          "",
          this.state?.active_tenant?.tenant_id
        )
          .then((res) => {
            this.setState({
              templateItems: res.data,
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
      getPORange = (show_season_id, tenant_id) => {
        PostWatchService.getData(
          Constants.postWatchServiceBaseUrl +
            "/getporangedetails?showSeasonId=" +
            show_season_id,
          tenant_id
        ).then(
          (response) => {
            this.setState(
              {
                poRangeItems: response.data,
              },
              () => {
                this.setActivePORange();
              }
            );
          },
          (err) => {}
        );
      };
      setPORange = (data) => {
        this.setState({ poRangeItems: data }, () => {
          this.setActivePORange();
        });
      };

      setActivePORange = () => {
        // Need to show only Active / Availble PO numbers
        let activePORange = _.filter(this.state.poRangeItems, {
          is_avail: "1",
        });
        if (activePORange) {
          this.setState({ poRangeActiveItems: activePORange });
        }
      };

      getUserDetails = () => {
        PostWatchService.getData(
          Constants.postWatchServiceBaseUrl + "/getuserdetails",
          this.state?.active_tenant?.tenant_id
        ).then((response) => {
          if (this._isMounted) {
            if (response) {
              let userProfile = response.data[0];
              this.setState({ user_profile: userProfile });
            }
          }
        });
      };
      setCurrentShowSelected = (value) => {
        this.setState({ currentShowSelected: value });
      };

      setCurrentSeasonSelected = (value) => {
        this.setState({ currentSeasonSelected: value });
      };

      setPoRangeLabel = (value) => {
        this.setState({ poRangeLabel: value });
      };

      adminMenuChange = (value) => {               
        if (value) {
          this.setState({activeTab: value});         
          
        }
      };

      render() {
        return this.state.isLoading ? (
          <div className="alignCenter ">
            <div className="align-center-vertical">
              <MDBSpinner role="status" color="primary"></MDBSpinner>
              <div>
                <span style={{ textAlign: "center" }}>
                  Loading Post Watch....
                </span>
              </div>
            </div>
          </div>
        ) : !this.state.isValidUser ? (
          <ErrorPage
            isLoggedIn={this.state.isValidUser}
            errorId={this.state.errorId}
            errorDesc={this.state.errorDesc}
          ></ErrorPage>
        ) : (
          <>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onIdle={this.onIdle}
              debounce={250}
              timeout={1000 * 60 * Constants.IDLE_TIMEOUT}
            />
            {this.state.appLoader && (
              <div class="app-loader-container">
                <Backdrop
                  classes={"app-loader"}
                  sx={{
                    color: "#1976d2",
                  }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            )}

            <UserContext.Provider
              value={{
                user_profile: this.state.user_profile,
                active_tenant: this.state.active_tenant,
                switchTenant: this.switchTenant.bind(this),
                dropDownList: this.state.dropDownList,
                activePage: this.state.activePage,
                activeTab: this.state.activeTab,
                setAppLoader: this.setAppLoader,
                templateItems: this.state.templateItems,
                getTemplates: this.getTemplates,
                setTemplateItems: this.setTemplateItems,
                getPORange: this.getPORange,
                setPORange: this.setPORange,
                poRangeItems: this.state.poRangeItems,
                poRangeActiveItems: this.state.poRangeActiveItems,
                getUserDetails: this.getUserDetails,
                setCurrentShowSelected: this.setCurrentShowSelected,
                currentShowSelected: this.state.currentShowSelected,
                setCurrentSeasonSelected: this.setCurrentSeasonSelected,
                currentSeasonSelected: this.state.currentSeasonSelected,
                setPoRangeLabel: this.setPoRangeLabel,
                poRangeLabel: this.state.poRangeLabel,
                adminMenuChange: this.adminMenuChange
              }}
            >
              <Router>
                <div className="h-100">
                  <HeaderContent
                    {...this.state}
                    {...this.props}
                    authState={this.props.authState}
                    switchTenant={this.switchTenant.bind(this)}
                  />
                  <MainContent {...this.state} {...this.props} />

                  {/* <LandingPage {...this.state} {...this.props}/> */}
                  {/* <MainContent {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        switchTenant={this.switchTenant.bind(this)} />
              */}
                </div>
              </Router>
            </UserContext.Provider>
          </>
        );
      }
    }
  )
);
