import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import IconTextField from "../../../components/SharedComponents/IconTextField/IconTextField";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import TableComponent from "../../../components/SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Modal from "../../../components/SharedComponents/Modal/Modal";
import PostWatchService from "../../../../src/services/service";
import * as Constants from "../../../constants/constants";
import "./Shows.scss";
import { ShowsConfig, TableFilter } from "./Config";
import { ShowsHboConfig, HboTableFilter } from "./HboConfig";
import { validateCharctersNumberSpecialCharOnly } from "../../../../src/Common/Helper";
import MessageModal from "../../../components/SharedComponents/MessageModal";
import Message from "../../../../src/Common/Messages.json";

export default withUserContext(
  class Shows extends React.Component {
    constructor(props) {
      super(props);
      this.deleteItem = null;
      this.state = {
        postInitiated: false,
        newModifyShowsModal: false,
        seasonsList: null,
        renderList: [],
        selectedShow: null,
        isLoading: false,
        tableListSorted: [],
       // filters: { ...TableFilter },
       filters: {...this.props?.userContext?.active_tenant?.tenant_id ==2 ? HboTableFilter:TableFilter},
        page: 1,
        rowsPerPage: 10,
        totalRows: 0,
        mode: null,
        sortBy: null,
        sortOrder: "asc",
        a_z: false,
        //config: JSON.parse(JSON.stringify({ ...ShowsConfig })),
        config: this.props?.userContext?.active_tenant?.tenant_id ==2 ? JSON.parse(JSON.stringify({ ...ShowsHboConfig })): JSON.parse(JSON.stringify({ ...ShowsConfig })),
        isMessageModalOpen: false,
        isErrorMessageModalOpen: false,
      };
    }
    handleOnChange = (field, value) => {
      this.setState((prevState) => ({
        userPostJson: {
          ...prevState.userPostJson,
          [field]: value,
        },
      }));
    };

    // async componentDidMount() {
    //   if((this.props?.userContext?.active_tenant?.tenant_id || this.props?.userContext?.user_profile?.tenant_id) == 2 ){
    //     const { ShowsConfig, TableFilter } = await import('./HboConfig');
    //     this.setState({  config: JSON.parse(JSON.stringify({ ...ShowsConfig })), filters:TableFilter });
    //   } else {
    //     const { ShowsConfig, TableFilter } = await import('./Config');
    //     this.setState({  config: JSON.parse(JSON.stringify({ ...ShowsConfig })), filters:TableFilter });
    //   }
    //   this.setState({ loading: false });
    //   this.getShowsDetails();
    //   this.getSeasons();
    // }

    componentDidMount() {
      
      this.getShowsDetails();
      this.getSeasons();
    }

    getSeasons = () => {
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/getseasons",
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            seasonsList: response.data,
          });
        },
        (err) => {
          console.log("error in get seasons");
        }
      );
    };

    onSave = () => {
      this.getShowsDetails();
      // this.setState({ newModifyShowsModal: false });
    };

    handleInlineEdits = (id, dataItem, mode = null) => {
      this.mode = mode;
      this.setState({
        newModifyShowsModal: !this.state.newModifyShowsModal,
        selectedShow: dataItem,
      });
    };

    saveInlineEdits = (data, index) => {
      this.postData(data[index]);
    };

    postData = (data) => {
      this.setState({ isPosting: true });
      PostWatchService.postDataParams(
        Constants.postWatchServiceBaseUrl +
          `/postshows?userId=` +
          this.props?.userContext?.user_profile?.user_id,
        data,
        this.props?.userContext?.active_tenant?.tenant_id,
        1
      ).then(
        (response) => {
          if (response.data[0]?.Level === "Error") {
            this.setState({
              isErrorMessageModalOpen: true,
              isMessageModalOpen: false,
              isPosting: false,
            });
          } else {
            this.setState({
              isMessageModalOpen: false,
              isPosting: false,
              postInitiated: false,
            });
            this.getSeasonDetails();
            this.deleteItem = null;
          }
        },
        (err) => {
          this.setState({ isPosting: false, isMessageModalOpen: false });
          console.log("Post agency details error: " + err);
        }
      );
    };

    getShowsDetails = () => {
      let filters = { ...this.state.filters };
      filters.page_count = this.state.rowsPerPage;
      filters.page_no = this.state.page;
      filters.sort_by = this.state.sortBy;
      filters.sort_order = this.state.sortOrder;
      this.setState({
        isLoading: true,
        searchShowNameTerm: null,
        searchPoPrefixTerm: null,
        filters,
      });
      PostWatchService.getDataWithFilters(
        Constants.postWatchServiceBaseUrl + "/getshows",
        filters,
        this.props?.userContext?.active_tenant?.tenant_id
      )
        .then((res) => {
          let tableResults = res.data?.records.map((obj) => {
            obj.is_active = obj.is_active ? 0 : 1;
            return obj;
          });
          let showsData = tableResults;
          this.setState({
            renderList: showsData,
            tableListSorted: tableResults,
            totalRows: res.data?.total_records_count,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log("err in getshows", err);
          this.setState({ isLoading: false });
        });
    };

    getPosition = (val) => {
      switch (val) {
        case "Inactive":
          return 0;
        case "PO Prefix":
          return 1;
        case "Show Name":
          return 3;
        case "Created On":
          return 7;
      }
    };

    getColumnName = (val) => {
      switch (val) {
        case "Inactive":
          return "is_active";
        case "PO Prefix":
          return "po_prefix";
        case "Show Name":
          return "show_name";
        case "Created On":
          return "created_at";
      }
    };

    sortColumn = (val) => {
      let Position = this.getPosition(val);
      let col_name = this.getColumnName(val);
      let order = null;
      let config = JSON.parse(JSON.stringify(this.state?.config));
      if (this.state.a_z && config?.headings[Position]?.icon != "") {
        if (config?.headings[Position]?.icon == "sort-alpha-up") {
          config?.headings.map((item) => {
            item.icon = "";
          });
          config.headings[Position].icon = "sort-alpha-down";
        } else {
          config?.headings.map((item) => {
            item.icon = "";
          });
          config.headings[Position].icon = "sort-alpha-up";
        }
        order = "desc";
        this.setState({ a_z: false });
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        this.setState({ a_z: true });
        config.headings[Position].icon = "sort-alpha-down";
        order = "asc";
      }
      this.setState(
        { sortBy: col_name, sortOrder: order, config, page: 1 },
        () => {
          this.getShowsDetails();
        }
      );
    };

    handleAddNewItem = (newDataItem) => {
      this.mode = null;
      this.setState({
        newModifyShowsModal: !this.state.newModifyShowsModal,
      });
    };

    onFilterChange = (filter, value) => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          [filter]: value,
        },
      }));
    };

    onKeyUpChange = (filter, value) => {
      this.setState({ page: 1 });
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [filter]: value,
            page_count: this.state.rowsPerPage,
            page_no: 1,
          },
        }),
        () => {
          this.getShowsDetails();
        }
      );
    };

    handleChangePage = (event, newPage) => {
      this.setState({ page: newPage + 1 }, () => {
        this.getShowsDetails();
      });
    };

    handleChangeRowsPerPage = (event) => {
      this.setState(
        {
          rowsPerPage: event.target.value,
          page: 1,
        },
        () => {
          this.getShowsDetails();
        }
      );
    };

    addNewShow = () => {
      this.mode = "new";
      this.handleInlineEdits(null, null, this.mode);
    };

    onModalClose = () => {
      this.setState({ newModifyShowsModal: false });
    };

    handleUserSelection = (item, node) => {
      if (node === "show_name") {
        this.props.history.push({
          pathname: "/showdetails",
          state: {
            po_id: item?.po_id || null,
            show_id: item?.show_id || null,
            season_id: item?.season_id || null,
            episode: -1,
          },
        });
      }
    };

    handleDelCallBack = (id, dataItem) => {
      this.deleteItem = dataItem;
      this.setState({ isMessageModalOpen: true });
    };

    onModalConfirm = () => {
      let item = { ...this.deleteItem };
      item.is_deleted = 1;
      this.postData(item);
    };
    onErrorModalConfirm = () => {
      this.setState({
        isErrorMessageModalOpen: false,
      });
      this.deleteItem = null;
    };
    render() {
      return (
        <div>
          {this.state.newModifyShowsModal && (
            <Modal
              open={this.state.newModifyShowsModal || false}
              modalName={"Shows"}
              selectedShow={this.state.selectedShow}
              comfirmModalTitle={
                this.mode === "new" ? "New Show" : "Modify Show"
              }
              mode={this.mode}
              onSave={this.onSave}
              onClose={this.onModalClose}
            />
          )}
          <MDBContainer className="ShowsContainer">
            <MDBRow>
              <MDBCol md={3} className="search">
                <IconTextField
                  placeholder={"Search By Show Name"}
                  value={this.state?.filters?.show_name || null}
                  onChange={(e) =>
                    validateCharctersNumberSpecialCharOnly(e.target.value) &&
                    this.onFilterChange(
                      "show_name",
                      e.target.value === null || e.target.value === ""
                        ? null
                        : e.target.value
                    )
                  }
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.onKeyUpChange(
                        "show_name",
                        e.target.value === null || e.target.value === ""
                          ? null
                          : e.target.value
                      );
                    }
                  }}
                  icon={"search"}
                />
              </MDBCol>
              <MDBCol md={2} className="search">
                <SelectField
                  options={
                    this.state.seasonsList?.map((item) => ({
                      value: item.season_id,
                      label: item.season_name,
                    })) || []
                  }
                  value={this.state?.filters?.season_id || null}
                  onChange={(e) =>
                    this.onKeyUpChange(
                      "season_id",
                      e.target.value === null || e.target.value === ""
                        ? null
                        : e.target.value
                    )
                  }
                  placeHolderText={"Search By Season"}
                />
              </MDBCol>
              <MDBCol md={2} className="search">
                <IconTextField
                  placeholder={"Search By PO Prefix "}
                  value={this.state?.filters?.po_prefix || null}
                  onChange={(e) =>
                    validateCharctersNumberSpecialCharOnly(e.target.value) &&
                    this.onFilterChange(
                      "po_prefix",
                      e.target.value === null || e.target.value === ""
                        ? null
                        : e.target.value
                    )
                  }
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.onKeyUpChange(
                        "po_prefix",
                        e.target.value === null || e.target.value === ""
                          ? null
                          : e.target.value
                      );
                    }
                  }}
                  icon={"search"}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="table-striped">
              <TableComponent
                list={this.state.tableListSorted || []}
                className={"clearencRecord"}
                config={this.state?.config}
                isLoading={this.state?.isLoading || false}
                sortCallback={this.sortColumn}
                stickyHeader={true}
                handleAddNewItem={this.handleAddNewItem.bind(this)}
                editCallback={this.handleInlineEdits.bind(this)}
                saveCallback={this.saveInlineEdits.bind(this)}
                addItemToList={true}
                hyperLinkNavigationCallback={(item, node) =>
                  this.handleUserSelection(item, node)
                }
                handleDelCallBack={this.handleDelCallBack}
              />
              <div className="shows-pagination d-flex justify-content-between">
                <BasicButton
                  text="New"
                  icon={"plus"}
                  onClick={this.addNewShow}
                />
                <TablePagination
                  component="div"
                  count={this.state?.totalRows}
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                  page={this.state?.page - 1}
                  onChangePage={this.handleChangePage}
                  rowsPerPage={this.state?.rowsPerPage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </div>
            </MDBRow>
          </MDBContainer>
          {this.state?.isMessageModalOpen && (
            <MessageModal
              open={this.state.isMessageModalOpen}
              showCustom={true}
              message={Message.deleteAdmin}
              title={"Confirm Deletion"}
              primaryButtonText={"Ok"}
              secondaryButtonText={"Cancel"}
              hideCancel={false}
              onConfirm={() => this.onModalConfirm()}
              handleClose={(e) => this.setState({ isMessageModalOpen: false })}
            />
          )}
          {this.state?.isErrorMessageModalOpen && (
            <MessageModal
              open={this.state.isErrorMessageModalOpen}
              showCustom={true}
              message={Message.deleteAdminError}
              title={"Message"}
              primaryButtonText={"Ok"}
              hideCancel={true}
              onConfirm={() => this.onErrorModalConfirm()}
              handleClose={(e) =>
                this.setState({ isErrorMessageModalOpen: false })
              }
            />
          )}
        </div>
      );
    }
  }
);
// export default withUserContext(Shows);
