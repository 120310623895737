import React from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBContainer,
  MDBNavbarLink,
  MDBDropdownLink,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { fade, makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import logo from "../assets/logo.png";
import { withOktaAuth } from "@okta/okta-react";
import { withRouter } from "react-router-dom";
import AsynchronousSearch from "../../src/components/SharedComponents/AsynchronousSerach/AsynchronousSearch";
import { withUserContext } from "../contexts/UserContext";
import MessageModal from "../components/SharedComponents/MessageModal";
import Modal from "../components/SharedComponents/Modal/Modal";
import * as appConstants from "../constants/appConstants";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { contactDetails } from "../home/LandingPage/Config";
import PostWatchService from "../services/service";
import * as Constants from "../constants/constants";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./HeaderContent.scss";
import { AppService } from "../services/AppService";
import { AdminSetupScreenList,AdminSetupHboScreenList, VendorAdminSetupHboScreenList } from "../header/Config";

export default withRouter(
  withUserContext(
    withOktaAuth(
      class HeaderContent extends React.Component {
        constructor(props) {
          super(props);
          this.logout = this.logout.bind(this);
          this.login = this.login.bind(this);
          this.state = {
            confirmSaveChanges: false,
            switch_tenant_id: 0,
            switch_tenant_name: "",
            isManageUser: false,
            showManageLookupModal: false,
            showContactListModal: false,
            hasNewLogs: false,
            showBellIconNotification: false,
            contextMenu: null,
            showCreatePoModal: false,
            showPoTemplateModal: false,
            showReportModal: false,
            loadingPDF: false,
            adminMenus: this.props.user_profile?.is_user_provisioner===0 ? this.assignMenu() :null
          };
        }

        assignMenu(){
          return [...(this.props?.userContext?.active_tenant?.tenant_id ==2) ? this.adminHBOMenuPermission() : AdminSetupScreenList];
        }
        adminHBOMenuPermission(){
          // const vendorPermissionExists = this.props?.userContext?.user_profile?.user_roles[0]?.permissions?.some(
          //   (permission) => (permission.permission_name).toUpperCase() === appConstants?.LABEL_VENDOR_MANAGE_LOOKUP
          // );
          const vendorPermissionExists = this.checkVendorPermission();
          if(vendorPermissionExists){
            return  VendorAdminSetupHboScreenList;
          }else{
            return AdminSetupHboScreenList;
          }
        }

        checkVendorPermission(){
          const vendorPermissionExists = this.props?.userContext?.user_profile?.user_roles[0]?.permissions?.some(
            (permission) => (permission.permission_name).toUpperCase() === appConstants?.LABEL_VENDOR_MANAGE_LOOKUP
          );
          return vendorPermissionExists;
        }

        async logout() {
          var len = localStorage.length;
          var keys = Object.keys(localStorage);
          for (var i = 0; i < len; i++) {
            var key = keys[i];
            if (key.indexOf("okta-") == -1) localStorage.removeItem(key);
          }

          this.props.oktaAuth.signOut("/");
        }

        async login() {
          // Redirect to '/' after logout
          this.props.authService.login("/");
        }
        handleSideNavActive = (value, routePath) => {
          this.props.history.push(routePath, this.state);
        };

        switchTenant = (tenant_id, tenant_name) => {
          this.setState({
            switch_tenant_id: tenant_id,
            switch_tenant_name: tenant_name,
            confirmSaveChanges: !this.state.confirmSaveChanges,
          });
        };

        confirmCancelChanges = (isCancel) => {
          //if (isCancel) {
          this.props.userContext.switchTenant(this.state.switch_tenant_id);
          //}
          //else {
          this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
          //}
        };
        closeNotification = () => {
          this.setState({ showBellIconNotification: false, hasNewLogs: false });
          AppService.refreshPhoneLogs();
        };

        componentWillReceiveProps(nextProps) {
          this.setState({
            isManageUser:
              nextProps.userContext?.active_tenant?.permissions?.isManageUser,
          });
        }
        componentDidUpdate(prevProps, prevState) {
        if (this.props?.userContext?.activeTab !== prevProps?.userContext?.activeTab) {
              this.adminMenuChange();
          }
        }

        downloadUrl = (url, reportName) => {
          window.open(url, "_blank");
        };

        downloadUserManualPDF = () => {
          this.setState({ loadingPDF: true });
          let fileName = "PostWatch-UserManual.pdf";
          PostWatchService.getDataParams(
            Constants.postWatchServiceBaseUrl +
              "/downloadfile?fileName=" +
              fileName,
            "",
            this.props.userContext?.active_tenant?.tenant_id
          ).then(
            (response) => {
              this.downloadUrl(response.data, "Show");
              this.setState({ loadingPDF: false });
            },
            (err) => {
              this.setState({ loadingPDF: false });
            }
          );
        };

        toggleCreatePoVisibility = () => {
          this.setState({
            showCreatePoModal: !this.state.showCreatePoModal,
          });
        };
        togglePoTemplateVisibility = () => {
          this.setState({
            showPoTemplateModal: !this.state.showPoTemplateModal,
          });
        };

        toggleReportVisibility = () => {
          this.setState({
            showReportModal: !this.state.showReportModal,
          });
        };
        toggleContactListModal = () => {
          this.setState({
            toggleContactListModal: !this.state.toggleContactListModal,
          });
        };
        adminMenuChange = () => {
          this.props.history.push("/Admin");        
                     
        };
        saveContact = (contactDetails) => {
          this.props.userContext.setAppLoader(true);
          PostWatchService.postDataParams(
            Constants.postWatchServiceBaseUrl + "/postcontactdetails ",
            contactDetails,
            this.props?.userContext?.user_profile?.tenant_id
          ).then(
            (response) => {
              this.props.userContext.setAppLoader(false);
              if (response.data.error) {
                AppService.showMessageModal("fail");
              } else {
                AppService.showMessageModal("success");
                this.props.userContext?.getUserContacts();
                // this.toggleContactListModal();
              }
            },
            (err) => {
              this.props.userContext.setAppLoader(false);
              AppService.showMessageModal("fail");
              console.log("Error in saving CallDetails", err);
            }
          );
        };
        getNotifications = (event) => {
          if (event.type == "click") {
            event.preventDefault();
            if (this.state.hasNewLogs) {
              this.setState({
                contextMenu: {
                  mouseX: event.clientX - 100,
                  mouseY: event.clientY + 15,
                },
                showBellIconNotification: true,
              });
            }
          }
        };

        render() {
          const bgColorBlue = { backgroundColor: "#0062AF" };
          const adminTitle = {
            fontSize: "13px",
            color: "#ffffff",
            padding: "2px",
          };
          const ptli = { paddingTop: "0.1rem" };
          const isAdmin = this.props?.userContext?.active_tenant?.permissions?.isAdmin;
          const isRegularAndVendorPermission = this.props?.userContext?.active_tenant?.permissions?.isRegular && this.checkVendorPermission();
          
          return (
            <div id="headerContent">
              {this.state.showCreatePoModal && (
                <Modal
                  open={this.state.showCreatePoModal || false}
                  modalName={"CreatePO"}
                  comfirmModalTitle={"Create a PO"}
                  onClose={this.toggleCreatePoVisibility}
                  {...this.props}
                />
              )}

              {this.state.showPoTemplateModal && (
                <Modal
                  open={this.state.showPoTemplateModal || false}
                  modalName={"PoTemplate"}
                  comfirmModalTitle={"PO Templates"}
                  onClose={this.togglePoTemplateVisibility}
                />
              )}

              {this.state.showReportModal && (
                <Modal
                  open={this.state.showReportModal || false}
                  modalName={"Reports"}
                  comfirmModalTitle={"Reports"}
                  onClose={this.toggleReportVisibility}
                />
              )}
              {this.state.toggleContactListModal && (
                <Modal
                  open={this.state.toggleContactListModal || false}
                  modalName={"ContactList"}
                  comfirmModalTitle={"Contact List"}
                  mode={appConstants.LABEL_FIND}
                  userData={this.props?.userContext.userContacts}
                  phoneTypeList={this.props?.userContext.phoneTypes}
                  currentGroupMember={this.props.currentGroupMember}
                  onClose={this.toggleContactListModal}
                  onSave={this.saveContact}
                />
              )}
              {/* <Router fixed="top"> */}
              <header>
                <div className="fixed-top">
                  <MDBNavbar bgColor="primary" dark expand="md" fixed sticky>
                    <MDBContainer fluid>
                      <MDBNavbarBrand className="mb-0 h1">
                        <Link
                          component={RouterLink}
                          className="action-icon"
                          to={"/"}
                        >
                          <img src={logo} alt="Logo" className="imageWidth" />
                        </Link>

                        {/* </MDBNavbarLink> */}
                        <h1 className="app-title">POST WATCH</h1>
                      </MDBNavbarBrand>
                      <MDBNavbarNav fullWidth={false} className="mb-2 mb-lg-0">
                        {" "}
                        {(this.props?.userContext?.active_tenant?.permissions
                          ?.isAdmin ||
                          this.props?.userContext?.active_tenant?.permissions
                            ?.isRegular) && (
                          <>
                            <MDBNavbarItem active>
                              <MDBNavbarLink
                                aria-current="page"
                                onClick={() => this.toggleCreatePoVisibility()}
                              >
                                Create PO
                              </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                              <MDBNavbarLink
                                onClick={() =>
                                  this.togglePoTemplateVisibility()
                                }
                              >
                                PO Templates
                              </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                              <MDBNavbarLink
                                onClick={() => this.toggleReportVisibility()}
                              >
                                Reports
                              </MDBNavbarLink>
                            </MDBNavbarItem>
                          </>
                        )}
                        {
                        
                        (isAdmin || isRegularAndVendorPermission) && (
                          <MDBNavbarItem className="admin-setup">
                            <MDBDropdown>
                              <MDBDropdownToggle
                                tag="a"
                                className="bgColorBlue shadow-none text-white angle-down"
                              >
                                <>
                                  <MDBNavbarLink>
                                    Admin Setup
                                    <MDBIcon
                                      fas
                                      size="1x"
                                      icon="angle-down"
                                      className="mr-2"
                                    />
                                  </MDBNavbarLink>
                                </>
                              </MDBDropdownToggle>
                              <MDBDropdownMenu className="text-end border border-1-quinary header-admin-nav">
                                {this.state.adminMenus?.map((e, index) => {
                                  return (
                                    <MDBDropdownItem
                                      onClick={() =>
                                        this.props.userContext.adminMenuChange(e.value)
                                      }
                                    >
                                      <MDBDropdownLink
                                        className="text-tertiary"
                                        active={false}
                                        to="/"
                                      >
                                        <Link
                                          component={RouterLink}
                                          to={{
                                            pathname: `/admin`,
                                            query: index,
                                          }}
                                        >
                                          {e.label}
                                        </Link>
                                      </MDBDropdownLink>
                                    </MDBDropdownItem>
                                  );
                                })}
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          </MDBNavbarItem>
                        )}
                        {this.props?.userContext?.active_tenant?.permissions
                          ?.isManageUser && (
                          <span className="user-maangement-login-text">
                            Logged in to User Role Management
                          </span>
                        )}
                        <MDBNavbarItem className="user-icon ">
                          <MDBDropdown>
                            <MDBDropdownToggle
                              tag="a"
                              className="bgColorBlue shadow-none text-white angle-down"
                            >
                              <MDBIcon
                                fas
                                size="2x"
                                icon="user-circle"
                                className="mr-2 bgColorBlue shadow-none text-white padding-hor-25 padding-ver-7"
                              />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="text-end border border-1-quinary">
                              {this.props.userContext?.user_profile?.user_roles?.map(
                                (tenant) => {
                                  if (
                                    tenant.tenant_id !=
                                    this.props.userContext?.active_tenant
                                      ?.tenant_id
                                  )
                                    return (
                                      <MDBDropdownItem
                                        data-active={false}
                                        key={tenant.tenant_id}
                                        onClick={this.switchTenant.bind(
                                          this,
                                          tenant.tenant_id,
                                          tenant.tenant_name
                                        )}
                                      >
                                        <MDBDropdownLink
                                          className="text-tertiary"
                                          active={false}
                                          to="/"
                                        >
                                          <small>
                                            {
                                              this.props.userContext
                                                ?.user_profile?.user_name
                                            }
                                          </small>
                                        </MDBDropdownLink>
                                        <MDBDropdownLink
                                          className="text-tertiary"
                                          active={false}
                                          to="/"
                                        >
                                          <small>{tenant.role_name}</small>
                                        </MDBDropdownLink>
                                      </MDBDropdownItem>
                                    );
                                  else
                                    return (
                                      <MDBDropdownItem
                                        data-active={false}
                                        key={tenant.tenant_id}
                                      >
                                        <small className="text-tertiary m-2">
                                          {
                                            this.props.userContext?.user_profile
                                              ?.full_name
                                          }
                                          {" - "}
                                          {tenant.role_name}
                                        </small>
                                      </MDBDropdownItem>
                                    );
                                }
                              )}

                              <MDBDropdownItem
                                active={false}
                                onClick={this.downloadUserManualPDF}
                              >
                                <MDBDropdownLink active={false} to="/">
                                  {this.state.loadingPDF ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : (
                                    "User Manual PDF"
                                  )}
                                </MDBDropdownLink>
                              </MDBDropdownItem>

                              <MDBDropdownItem
                                active={false}
                                className={
                                  !this.props.isLoggedIn ? "hide" : "show"
                                }
                                disabled={!this.props.isLoggedIn}
                                onClick={this.logout}
                              >
                                <MDBDropdownLink
                                  className="fw-bold"
                                  active={false}
                                  to="/"
                                >
                                  Logout
                                </MDBDropdownLink>
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBNavbarItem>
                      </MDBNavbarNav>
                    </MDBContainer>
                  </MDBNavbar>
                  <MessageModal
                    open={this.state.confirmSaveChanges}
                    title={"Unsaved Changes"}
                    message={
                      "Switching tenant will lose any unsaved changes. Do you want to proceed?"
                    }
                    primaryButtonText={"Yes"}
                    secondaryButtonText={"No"}
                    ononfirm={() => this.confirmCancelChanges()}
                    handleClose={(e) =>
                      this.setState({ confirmSaveChanges: false })
                    }
                  />
                </div>
                {this.state.showBellIconNotification ? (
                  <Menu
                    open={this.state.contextMenu !== null}
                    onClose={this.handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                      this.state.contextMenu !== null
                        ? {
                            top: this.state.contextMenu.mouseY,
                            left: this.state.contextMenu.mouseX,
                          }
                        : null
                    }
                  >
                    <MenuItem
                      className="bellIconNotification"
                      onClick={this.closeNotification}
                    >
                      <div class="content">
                        <MDBRow className="icon-with-text">
                          <MDBCol size={1}>
                            <MDBIcon size="2x" icon="bell"></MDBIcon>
                          </MDBCol>
                          <MDBCol size={8}>
                            <span>New messages have been added</span>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="only-text">
                          <MDBCol size={1}></MDBCol>
                          <MDBCol size={8}>
                            <span>
                              Click here to load, or refresh the page.
                            </span>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MenuItem>
                  </Menu>
                ) : (
                  ""
                )}
              </header>
              {/* </Router> */}
            </div>
          );
        }
      }
    )
  )
);
