export const Tabs = [
  {
    value: 1,
    label: "Purchase Orders",
  },
  {
    value: 2,
    label: "Commits by Detail",
  },
];

export const HboTabs = [
  {
    value: 1,
    label: "Purchase Orders",
  }
];

export const Vendors = [
  {
    label: "Vendor 1",
    value: { label: "Vendor 1", value: 1 },
  },
  {
    label: "Vendor 2",
    value: { label: "Vendor 2", value: 2 },
  },
  {
    label: "Vendor 3",
    value: { label: "Vendor 3", value: 3 },
  },
  {
    label: "Vendor 4",
    value: { label: "Vendor 4", value: 4 },
  },
  {
    label: "Vendor 5",
    value: { label: "Vendor 5", value: 5 },
  },
  {
    label: "Vendor 7",
    value: { label: "Vendor 7", value: 7 },
  },
  {
    label: "Vendor 8",
    value: { label: "Vendor 8", value: 8 },
  },
  {
    label: "Vendor 9",
    value: { label: "Vendor 9", value: 9 },
  },
];
export const tablePOData = [
  {
    poid: "2356123",
    date: "22/04/2022",
    vendor: "My Vendor",
    totalcost: "$2363496",
    extendedcost: "$97834",
    revisionnotes: "My Revision Notes",
    createdby: "Naveen",
  },
  {
    poid: "7775678",
    date: "22/04/2022",
    vendor: "My Vendor",
    totalcost: "$2363496",
    extendedcost: "$97834",
    revisionnotes: "My Revision Notes",
    createdby: "Naveen",
  },
];

export const poListConfig = {
  headings: [
    {
      headingLabel: "PO #",
      icon: "",
      width: "5%",
      inputType: "text",
    },
    {
      headingLabel: "Date",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Vendor",
      icon: "",
      width: "20%",
      inputType: "text",
    },
    {
      headingLabel: "Total Cost",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Extended Cost",
      icon: "",
      width: "12%",
      inputType: "text",
    },
    {
      headingLabel: "Revision Notes",
      icon: "",
      width: "28%",
      inputType: "text",
    },
    {
      headingLabel: "Created By",
      icon: "",
      width: "10%",
      inputType: "text",
    },
  ],
  dataNodes: [
    "po_number",
    "created_at",
    "vendor_name",
    "total_cost_value",
    "extended_cost_value",
    "notes",
    "full_name",
  ],
  primaryKey: "po_id",
  hyperlinks: ["po_number"],
  actions: ["file"],
  showLoader: false
};
